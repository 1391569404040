import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import "../../Reports.css"
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import {toEur} from "../../../../../utils/tools";

export const Financial = () => {
  const [yearReports, setYearReports] = useState();
  const [weekReports, setWeekReports] = useState();
  const [dayReports, setDayReports] = useState();
  const [walletStat, setWalletStat] = useState();
  const [expandedId, setExpandedId] = useState();

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


  const getAllYearReports = async () => {
    try {
      await client.getAllYearsReports().then(setYearReports);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const getAllWeekReports = async () => {
    try {
      await client.getAllWeekReports().then(setWeekReports);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const getAllDayReportsById = async (id) => {
    try {
      await client.getAllDaysReportsById(id).then(setDayReports);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const getWalletStat = async () => {
    try {
      await client.getWalletsTotalStat().then(setWalletStat);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  useEffect(() => {
    getAllWeekReports();
    getAllYearReports();
    getWalletStat();
  }, []);


  return (
    <div className="reports">

      <div>
        {walletStat && <Accordion className="report-row">
            <AccordionSummary>
              <div className="report-row-wallet-caption">
                <div>Cash Wallets:</div>
                <div>{toEur(walletStat.cashWallets)}</div>
                <div>Trading Wallets:</div>
                <div>{toEur(walletStat.tradingWallets)}</div>
                <div>Free Tokens:</div>
                <div>{toEur(walletStat.lmgtFree, false)}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
            </AccordionDetails>
          </Accordion>
        }
      </div>

      <div>
        {yearReports && yearReports.map((e, i) => {
          return <Accordion key={i} className="report-row">
            <AccordionSummary>
              <div className="report-row-caption">
                <div>{e.year}</div>
                <div>New customers</div>
                <div>{e.customers}</div>
                <div>Paid</div>
                <div>{e.imps}</div>
                <div>Total sales</div>
                <div>{toEur(e.sales)}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
            </AccordionDetails>
          </Accordion>
        })}
      </div>

      <div>
        {weekReports && weekReports.map((e, i) => {
          const id = e.year + "-" + e.week;
          return <Accordion key={i} className="report-row" expanded={expandedId === id} onChange={(event, expanded) => {
            if (expanded) {
              setExpandedId(id);
              getAllDayReportsById(id);
            } else {
              setDayReports(null);
              setExpandedId(null);
            }
          }
          }>
            <AccordionSummary>
              <div
                className={expandedId !== id ? "report-row-caption" : "report-row-caption report-row-caption-expanded"}>
                <div>Week {id}</div>
                <div>New customers</div>
                <div>{e.customers}</div>
                <div>Paid</div>
                <div>{e.imps}</div>
                <div>Total sales</div>
                <div>{toEur(e.sales)}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {dayReports ?
                <div className="report-details">
                  <div className="report-details-row">
                    <div>Week {e.week}</div>
                    {days.map((d, i) => {
                      return <div key={i}>{d}</div>
                    })}
                    <div>Total</div>
                  </div>

                  <div className="report-details-row">
                    <div>Customers</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{d[0].customers}</div>
                      } else {
                        return <div key={i}>0</div>
                      }
                    })}
                    <div>{e.customers}</div>
                  </div>

                  <div className="report-details-row">
                    <div>IMPs</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{d[0].imps}</div>
                      } else {
                        return <div key={i}>0</div>
                      }
                    })}
                    <div>{e.imps}</div>
                  </div>

                  <div className="report-details-row">
                    <div>Sales</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].sales)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.sales)}</div>
                  </div>

                  <div className="report-details-separator"/>

                  <div className="report-details-row">
                    <div>DS bonus</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].dsBonus)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.dsBonus)}</div>
                  </div>

                  <div className="report-details-row">
                    <div>Binary</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].binaryBonus)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.binaryBonus)}</div>
                  </div>

                  <div className="report-details-row">
                    <div>Matching</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].matchingBonus)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.matchingBonus)}</div>
                  </div>

                  <div className="report-details-row">
                    <div>Rank</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].rankBonus)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.rankBonus)}</div>
                  </div>

                  <div className="report-details-row">
                    <div>Lifestyle</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].lsBonus)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.lsBonus)}</div>
                  </div>

                  <div className="report-details-row">
                    <div>Manual</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].manualBonus)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.manualBonus)}</div>
                  </div>

                  <div className="report-details-row">
                    <div>SUM</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].sum)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.sum)}</div>
                  </div>

                  <div className="report-details-separator"/>

                  <div className="report-details-row">
                    <div>Net</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i} className={d[0].net < 0 ? "reports-negative-value" : ""}>{toEur(d[0].net)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.net)}</div>
                  </div>

                  <div className="report-details-separator"/>

                  <div className="report-details-row">
                    <div>Pay-outs</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{toEur(d[0].payouts)}</div>
                      } else {
                        return <div key={i}>{toEur(0)}</div>
                      }
                    })}
                    <div>{toEur(e.payouts)}</div>
                  </div>

                  <div className="report-details-row">
                    <div>Binary %</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        const p = d[0].binaryPercent;
                        return <div key={i}>{p > 0 ? p + " %" : "-"}</div>
                      } else {
                        return <div key={i}>-</div>
                      }
                    })}
                    <div>{e.binaryPercent} %</div>
                  </div>

                  <div className="report-details-row">
                    <div>Total %</div>
                    {days.map((day, i) => {
                      const d = dayReports.filter((d) => d.day === day && d.week === e.week && d.year === e.year);
                      if (d.length > 0) {
                        return <div key={i}>{d[0].totalPercent} %</div>
                      } else {
                        return <div key={i}>-</div>
                      }
                    })}
                    <div>{e.totalPercent} %</div>
                  </div>

                </div>
              : "Loading..."}
            </AccordionDetails>
          </Accordion>
        })}
      </div>
    </div>
  )
}
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {notification} from "../../../../../components/Notification";
import {client} from "../../../../../services/client";
import {ROUTE} from "../../../../../constants/routes";
import "../../Resources.css";

const TYPES = [
  {id: 1, value: "PRESENTATION", title: "Presentation"},
  {id: 2, value: "VIDEO", title: "Video"},
  {id: 3, value: "DOCUMENTS", title: "Documents"},
  {id: 4, value: "PRINT", title: "Print materials"},
];

export const ResourceCreate = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [type, setType] = useState(TYPES[0]);
  const history = useHistory();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (e.target.title.value.length < 1) {
      notification.warning("Title required");
      setLoading(false)
      return;
    }

    const formData = new FormData();
    formData.append('title', e.target.title.value);
    formData.append('type', type.value);

    if (type.value === "VIDEO") {
      const link = e.target.link.value.trim();
      setFile(null);
      if (link.length < 5 || link.substring(0,4) !== "http") {
        notification.warning("Wrong link");
        setLoading(false)
        return;
      }
      formData.append('link', link);

      await client.createVideoResource(formData)
        .then(() => {
          history.push(ROUTE.RESOURCES.replace(":tab", "all"));
        })
        .catch((error) => notification.warning(error.message))
        .finally(() => setLoading(false));
    } else {
      formData.append('file', file);
      formData.append('link', null);

      await client.createResource(formData)
        .then(() => {
          history.push(ROUTE.RESOURCES.replace(":tab", "all"));
        })
        .catch((error) => notification.warning(error.message))
        .finally(() => setLoading(false));
    }
  }

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  }

  return (
    <>
      <div className="block">
        <div className="title">Create Resource</div>
        <form onSubmit={handleSubmit} className="resources-form">
          <div>
            <div className="resources-form-title">Type</div>
            {TYPES.map((el, i) => {
              return <div key={i}>
                <label className="radio">
                  <input
                    type="radio"
                    value={el.value}
                    checked={type && type.id === el.id}
                    onChange={() => setType(el)}
                  />

                  {el.title}
                </label>
              </div>
            })}

          </div>
          <div>
            <div>Title</div>
            <input
              id="title"
              name="title"
              type="text"
              required
              autoComplete="off"
            />
          </div>
          {type && (type.value !== "VIDEO" ?
              <div>
                <div>File</div>
                <input
                  accept="application/zip, application/x-iwork-keynote-sffkey, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .ppt, .pptx, docx, zip, key, text/plain, application/pdf, image/*"
                  id="file"
                  type="file"
                  onChange={handleFileUpload}
                />
              </div> :
              <div>
                <div>Link</div>
                <input
                  id="link"
                  name="link"
                  type="text"
                  required
                  autoComplete="off"
                />
              </div>
          )}


          <div className="resources-form-button-container">
            {loading ? <span className="loader"/> :
              <button type="submit" className="button-green">Save</button>}
          </div>
        </form>
      </div>
    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import {client} from "../../../../../services/client";
import {searchParamsToObject} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";
import {Edit} from "@mui/icons-material";
import {ROUTE} from "../../../../../constants/routes";

export const TranslationsTable = () => {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [locale, setLocale] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'place', value: 'Place', direction: 'asc', sorted: true},
    {name: 'locale', value: 'Locale', direction: 'asc', sorted: true},
    {name: 'name', value: 'Name', direction: 'asc', sorted: true},
    {name: 'key', value: 'Key', direction: 'asc', sorted: true},
    {name: 'value', value: 'Value', direction: 'asc', sorted: true},
    {name: 'actions', value: 'Actions', direction: 'asc', sorted: true}
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    const _locale = _params.get("locale");
    if (_locale) {
      setLocale(_locale)
    }
    await client.getAllTranslations({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname.split("/", 3)[2] === "translations") {
        getData();
      } else {
        unlisten();
      }
    })
  }, [history]);


  const renderActions = (e) => {
    return (
      <>
        <Tooltip title="Details" aria-label="edit">
          <Edit className="action-icon"
                onClick={() => {
                  if (locale) {
                    history.push(ROUTE.TRANSLATIONS.replace(":tab", "add_record") + "?id=" + e.id + "&action=update" + "&locale=" + locale);
                  } else {
                    history.push(ROUTE.TRANSLATIONS.replace(":tab", "add_record") + "?id=" + e.id + "&action=update");
                  }
                }}/>
        </Tooltip>
      </>
    )
  }


  return (
    <div className="translations">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{e.place}</TableCell>
                <TableCell>{e.locale}</TableCell>
                <TableCell>{e.name}</TableCell>
                <TableCell>{e.key}</TableCell>
                <TableCell>{e.value}</TableCell>
                <TableCell>{renderActions(e)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <div className="reset-filters" onClick={() => history.push(ROUTE.TRANSLATIONS.replace(":tab", "all"))}>Reset
      </div>
    </div>

  )
}
import React, {useEffect, useState} from "react";
import "../../Translations.css";
import {client} from "../../../../../services/client";
import {useHistory, useParams} from "react-router-dom";
import {ROUTE} from "../../../../../constants/routes";
import {notification} from "../../../../../components/Notification";

export const AddTranslations = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("create");
  const [locale, setLocale] = useState(null);
  const [data, setData] = useState({
    place: "",
    key: "",
    value: ""
  });

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id");
    const action = params.get("action");
    const _locale = params.get("locale");
    if (_locale) {
      setLocale(_locale);
    }
    if (id && action && action === "update") {
      setId(+id);
      setAction("update");
      client.getTranslationById(id)
        .then(setData)
        .catch((error) => error.message);
    }
  }, [])

  const add = async (e) => {
    e.preventDefault();
    if (data.place.length < 2 || data.key.length < 2 || data.value.length < 1) {
      notification.warning("Please fill all fields");
      return;
    }
    setLoading(true);

    if (action === "create") {
      await client.addTranslationKey(data)
        .then(() => {
          if (locale) {
            history.push(ROUTE.TRANSLATIONS.replace(":tab", "all") + "?locale=" + locale);
          } else {
            history.push(ROUTE.TRANSLATIONS.replace(":tab", "all"));
          }
          notification.success("New record added");
        })
        .catch((error) => notification.warning(error.message))
        .finally(() => setLoading(false));
    } else if (action === "update") {
      await client.changeTranslationValue(id, data)
        .then(() => {
          console.log(locale)
          if (locale) {
            history.push(ROUTE.TRANSLATIONS.replace(":tab", "all") + "?locale=" + locale);
          } else {
            history.push(ROUTE.TRANSLATIONS.replace(":tab", "all"));
          }
          notification.success("Record changed");
        })
        .catch((error) => notification.warning(error.message))
        .finally(() => setLoading(false));
    }
  }

  const changeField = (e) => {
    switch (e.target.name) {
      case "place":
        setData({...data, place: e.target.value});
        break;
      case "key":
        setData({...data, key: e.target.value});
        break;
      case "value":
        setData({...data, value: e.target.value});
        break;
    }
  }

  return (
    <div className="block">
      <div className="title">Add new record</div>

      <form className="translations-form" onSubmit={add}>
        <div>
          <div>Place</div>
          <input
            type="text"
            name="place"
            value={data.place}
            onChange={changeField}
          />
        </div>

        <div>
          <div>Key</div>
          <input
            type="text"
            name="key"
            value={data.key}
            onChange={changeField}
          />
        </div>

        <div>
          <div>Value</div>
          <textarea
            name="value"
            rows={5}
            value={data.value}
            onChange={changeField}
          />
        </div>

        <div className="user-edit-form-button-container">
          {loading ? <span className="loader"/> :
            <button className="button-green" type="submit">Save</button>}
        </div>
      </form>
    </div>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {client} from "../../../../../services/client";
import {searchParamsToObject} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import {Done} from "@mui/icons-material";

export const ExchangeOrdersTable = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [updated, setUpdated] = useState(0);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'uid', value: 'UID', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'remain', value: 'Remain', direction: 'asc', sorted: true},
    {name: 'price', value: 'Price', direction: 'asc', sorted: true},
    {name: 'pricePerCoin', value: 'Price per coin', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'created', value: 'Created', direction: 'asc', sorted: true},
    {name: 'updatedAt', value: 'Executed', direction: 'asc', sorted: true},
    {name: 'actions', value: 'Actions', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getAllExchangeOrders({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, updated]);


  const renderStatus = (e) => {
    if (e.status === "Active") {
      return <span className="td-label td-label-blue">{e.status.toLowerCase()}</span>
    } else if (e.status === "Completed") {
      return <span className="td-label td-label-green">{e.status.toLowerCase()}</span>
    } else if (e.status === "Canceled") {
      return <span className="td-label td-label-orange">{e.status.toLowerCase()}</span>
    } else if (e.status === "Failed") {
      return <span className="td-label td-label-red">{e.status.toLowerCase()}</span>
    } else if (e.status === "Expired") {
      return <span className="td-label td-label-gray">{e.status.toLowerCase()}</span>
    } else {
      return <span>{e.status.toLowerCase()}</span>
    }
  }

  const handleExecute = async (e) => {
    await client.executeOrder(e.id)
      .then(() => {
        setUpdated(updated + 1);
        notification.success("Completed");
      })
      .catch((error) => notification.warning(error.message));
  }

  const renderActions = (e) => {
    switch (e.status) {
      case "Active":
        return <Tooltip title="Execute" aria-label="execute">
          <Done className="action-icon" onClick={() => handleExecute(e)}/>
        </Tooltip>
    }
  }

  const renderType = (e) => {
    if (e.type === "BUY") {
      return <span className="td-label td-label-blue">{e.type}</span>
    } else if (e.type === "SELL") {
      return <span className="td-label td-label-green">{e.type}</span>
    } else {
      return <span>{e.type}</span>
    }
  }

  return (
    <div className="block">
      <div className="title">Exchange orders</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{e.userId}</TableCell>
                <TableCell>{e.amount}</TableCell>
                <TableCell>{e.remain}</TableCell>
                <TableCell>{e.price}</TableCell>
                <TableCell>{e.pricePerCoin}</TableCell>
                <TableCell>{renderType(e)}</TableCell>
                <TableCell>{renderStatus(e)}</TableCell>
                <TableCell>{e.createdAt}</TableCell>
                <TableCell>{e.executedAt}</TableCell>
                <TableCell>{renderActions(e)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
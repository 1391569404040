import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {useParams} from "react-router-dom";
import {EVENTS_TABS} from "./constants/tabs";
import {EventCreate} from "./components/EventCreate/EventCreate";
import {EventsTable} from "./components/EventsTable/EventsTable";

export const Events = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "all") {
      return <EventsTable/>
    }
    if (tab === "create") {
      return <EventCreate/>
    }
  }

  return (
    <>
      <Tabs tabs={EVENTS_TABS} route={ROUTE.EVENTS}/>

      {renderContent()}
    </>
  )
}
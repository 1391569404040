import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {useParams} from "react-router-dom";
import {VAULT_TABS} from "./constants/vaultTabs";
import {VaultTable} from "./components/VaultTable/VaultTable";
import {VaultTransactionsTable} from "./components/VaultTransactionsTable/VaultTransactionsTable";
import {VaultWithdrawalsTable} from "./components/VaultWithdrawalsTable/VaultWithdrawalsTable";

export const Vault = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "all") {
      return <VaultTable/>
    }
    if (tab === "transactions") {
      return <VaultTransactionsTable/>
    }
    if (tab === "withdrawals") {
      return <VaultWithdrawalsTable/>
    }
  }
  return (
    <>
      <Tabs tabs={VAULT_TABS} route={ROUTE.VAULTS}/>

      {renderContent()}
    </>
  )
}
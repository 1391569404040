import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {notification} from "../../../../../components/Notification";
import {client} from "../../../../../services/client";
import {ROUTE} from "../../../../../constants/routes";
import "../../Events.css";

const TYPES = [
  {id: 1, value: "WEBINAR", title: "Webinar"},
  {id: 2, value: "MEETING", title: "Meeting"},
];

export const EventCreate = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(TYPES[0]);
  const history = useHistory();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (e.target.content.value.length < 1) {
      notification.warning("Content required");
      setLoading(false)
      return;
    }

    await client.createEvent({
      content: e.target.content.value,
      type: type.value,
      date: e.target.date.value + "T10:00:00",
    })
      .then(() => {
        history.push(ROUTE.EVENTS.replace(":tab", "all"));
        notification.success("Event created")
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }


  return (
    <>
      <div className="block">
        <div className="title">Create Event</div>
        <form onSubmit={handleSubmit} className="events-form">
          <div>
            <div className="events-form-title">Type</div>
            {TYPES.map((el, i) => {
              return <div key={i}>
                <label className="radio">
                  <input
                    type="radio"
                    value={el.value}
                    checked={type && type.id === el.id}
                    onChange={() => setType(el)}
                  />

                  {el.title}
                </label>
              </div>
            })}

          </div>
          <div>
            <div>Content</div>
            <textarea
              id="content"
              name="content"
              required
              rows={10}
              cols={105}
            />
          </div>
          <div>
            <div>Date (format: YYYY-MM-DD)</div>
            <input
              id="date"
              name="date"
              type="text"
              required
              autoComplete="off"
            />
          </div>

          <div className="events-form-button-container">
            {loading ? <span className="loader"/> :
              <button type="submit" className="button-green">Save</button>}
          </div>
        </form>
      </div>
    </>
  )
}
import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users
  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  getAllUsers = (params) => api.get(API_URLS.USERS.ALL, {params})
  getLSEligible = (params) => api.get(API_URLS.USERS.LS_ELIGIBLE, {params})
  getUserById = (id) => api.get(API_URLS.USERS.GET_BY_ID(id))
  updateUser = (id, data) => api.put(API_URLS.USERS.UPDATE(id), data)
  getUserStat = (id) => api.get(API_URLS.USERS.GET_USER_STAT(id))
  getBonusRunStat = (id) => api.get(API_URLS.USERS.GET_BONUS_RUN_STAT(id))
  getBonusStat = (id) => api.get(API_URLS.USERS.GET_BONUS_STAT(id))
  getLastWeekStat = (id) => api.get(API_URLS.USERS.GET_LAST_WEEK_STAT(id))
  getSponsorSubtree = (id) => api.get(API_URLS.USERS.SPONSOR_SUBTREE(id));
  getBinarySubtree = (id) => api.get(API_URLS.USERS.BINARY_SUBTREE(id));
  getLoginHistory = (params) => api.get(API_URLS.USERS.LOGIN_HISTORY, {params})
  changePack = (userId, packId) => api.put(API_URLS.USERS.CHANGE_PACK(userId, packId));
  toggleTag = (userId) => api.put(API_URLS.USERS.TOGGLE_TAG(userId));
  toggleBan = (userId) => api.put(API_URLS.USERS.TOGGLE_BAN(userId));
  togglePayout = (userId) => api.put(API_URLS.USERS.TOGGLE_PAYOUT(userId));
  refundUser = (userId) => api.put(API_URLS.USERS.REFUND(userId));
  manualBinaryBonus = (data) => api.post(API_URLS.USERS.MANUAL_BINARY_BONUS, data);

  //Wallets
  getWallet = (userId) => api.get(API_URLS.WALLETS.GET_BY_USER_ID(userId))
  topupWallet = (data) => api.post(API_URLS.WALLETS.TOPUP, data)
  withdrawWallet = (data) => api.post(API_URLS.WALLETS.WITHDRAW, data)
  updateLrtBalances = () => api.get(API_URLS.WALLETS.UPDATE_LRT_BALANCES)
  getLmgtMainBalance = () => api.get(API_URLS.WALLETS.GET_LMGT_MAIN_BALANCE)
  getWalletsTotalStat = () => api.get(API_URLS.WALLETS.GET_TOTAL_STAT)

  //Orders
  getAllOrders = (params) => api.get(API_URLS.ORDERS.ALL, {params})
  getOrderById = (id) => api.get(API_URLS.ORDERS.GET_BY_ID(id))
  cancelOrder = (id) => api.put(API_URLS.ORDERS.CANCEL(id));

  //Epins
  getAllEpins = (params) => api.get(API_URLS.EPINS.ALL, {params})

  //Transactions
  getAllTransactions = (params) => api.get(API_URLS.TRANSACTIONS.ALL, {params})

  //Stat
  getDashboardStat = () => api.get(API_URLS.STAT.DASHBOARD);
  getPackHolders = () => api.get(API_URLS.STAT.PACK_HOLDERS);
  getCsvWithLrtBalances = () => api.get(API_URLS.STAT.LRT_BALANCES);
  getCsvWithLrtOrders = () => api.get(API_URLS.STAT.LRT_ORDERS);

  //Packs
  getPacks = () => api.get(API_URLS.PACKS.GET_ALL);
  getAdminDataPacks = () => api.get(API_URLS.PACKS.GET_ADMIN_DATA);
  updatePack = (id, data) => api.put(API_URLS.PACKS.UPDATE(id), data);

  //LRT Packs
  getLrtPacks = (params) => api.get(API_URLS.LRT_PACKS.GET_ALL, {params});
  updateLrtPack = (id, data) => api.put(API_URLS.LRT_PACKS.UPDATE(id), data);

  //News
  getAllNews = (params) => api.get(API_URLS.NEWS.GET_ALL, {params});
  createNews = (data) => api.post(API_URLS.NEWS.CREATE, data);

  //Education
  getAllEducationTime = (params) => api.get(API_URLS.EDUCATION.GET_ALL, {params});
  createEducationTime = (data) => api.post(API_URLS.EDUCATION.CREATE, data);

  //Statements
  getAllBonusRun = (params) => api.get(API_URLS.STATEMENTS.GET_ALL_BONUS_RUN, {params});

  //Translations
  getAllTranslations = (params) => api.get(API_URLS.TRANSLATIONS.ALL, {params})
  getAvailableLangs = () => api.get(API_URLS.TRANSLATIONS.GET_AVAILABLE_LANGS)
  addTranslationLang = (data) => api.post(API_URLS.TRANSLATIONS.ADD_NEW_LANG, data)
  addTranslationKey = (data) => api.post(API_URLS.TRANSLATIONS.ADD_NEW_KEY, data)
  getTranslationById = (id) => api.get(API_URLS.TRANSLATIONS.GET_BY_ID(id))
  changeTranslationValue = (id, data) => api.put(API_URLS.TRANSLATIONS.CHANGE_VALUE(id), data)

  //Payouts
  getAllPayouts = (params) => api.get(API_URLS.PAYOUTS.GET_ALL, {params});
  approvePayout = (id) => api.put(API_URLS.PAYOUTS.APPROVE(id));
  cancelPayout = (id, userId) => api.put(API_URLS.PAYOUTS.CANCEL(id, userId));
  withdrawCryptoApi = (data) => api.post(API_URLS.PAYOUTS.WITHDRAW_CRYPTO_API, data);

  //Reports
  getAllWeekReports = () => api.get(API_URLS.REPORTS.GET_ALL_WEEK_REPORTS);
  getAllYearsReports = () => api.get(API_URLS.REPORTS.GET_ALL_YEARS_REPORTS);
  getAllDaysReportsById = (id) => api.get(API_URLS.REPORTS.GET_ALL_DAYS_REPORTS_BY_ID(id))

  //Resources
  getAllResources = (params) => api.get(API_URLS.RESOURCES.GET_ALL, {params});
  createResource = (data) => api.post(API_URLS.RESOURCES.CREATE, data, {
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  createVideoResource = (data) => api.post(API_URLS.RESOURCES.CREATE_VIDEO, data, {
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  toggleResourceStatus = (id) => api.put(API_URLS.RESOURCES.TOGGLE_STATUS(id));
  deleteResource = (id) => api.delete(API_URLS.RESOURCES.DELETE(id));

  //Events
  getAllEvents = (params) => api.get(API_URLS.EVENTS.GET_ALL, {params});
  toggleEventStatus = (id) => api.put(API_URLS.EVENTS.TOGGLE_STATUS(id));
  createEvent = (data) => api.post(API_URLS.EVENTS.CREATE, data);


  //Exchange
  getAllExchangeOrders = (params) => api.get(API_URLS.EXCHANGE.GET_ALL_ORDERS, {params});
  getAllExchangeTransactions = (params) => api.get(API_URLS.EXCHANGE.GET_ALL_TRANSACTIONS, {params});
  executeOrder = (id) => api.put(API_URLS.EXCHANGE.EXECUTE(id));
  getExchangeTransactionsByOrderId = (id) => api.get(API_URLS.EXCHANGE.GET_TRANSACTIONS_BY_ORDER(id));

  //Vault
  getAllVaults = (params) => api.get(API_URLS.VAULTS.GET_ALL_VAULTS, {params});
  getAllVaultsTransactions = (params) => api.get(API_URLS.VAULTS.GET_ALL_VAULT_TRANSACTIONS, {params});
  getAllVaultsWithdrawals = (params) => api.get(API_URLS.VAULTS.GET_ALL_VAULT_WITHDRAWALS, {params});
  executeVaultWithdrawal = (id) => api.put(API_URLS.VAULTS.EXECUTE(id));

  //Loyalty
  getAllLoyaltyRewards = (params) => api.get(API_URLS.LOYALTY.GET_ALL, {params});

  //Settings
  getSettings = (params) => api.get(API_URLS.SETTINGS.ALL, {params});
  getSettingsById = (id) => api.get(API_URLS.SETTINGS.GET_BY_ID(id))
  updateSettings = (id, data) => api.put(API_URLS.SETTINGS.UPDATE(id), data);

  //Bonuses
  runProfitSharing = (data) => api.post(API_URLS.BONUSES.RUN_PROFIT_SHARING, data, {
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const client = new Client();

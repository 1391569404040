import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow, Tooltip,
} from "@mui/material";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import {searchParamsToObject} from "../../../../../utils/tools";
import "../../Resources.css"
import {Delete, Edit} from "@mui/icons-material";
import {ROUTE} from "../../../../../constants/routes";

export const ResourcesTable = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [changed, setChanged] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'title', value: 'Title', direction: 'asc', sorted: true},
    {name: 'link', value: 'Link', direction: 'asc', sorted: true},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: true},
    {name: 'actions', value: 'Actions', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getAllResources({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, changed]);

  const renderType = (e) => {
    return <span>{e.type.toLowerCase()}</span>
  }

  const renderLink = (e) => {
    return <a href={e.link} target="_blank">{e.link}</a>
  }

  const renderStatus = (e) => {
    return <Switch checked={e.status === "ACTIVE"} onChange={() => toggleStatus(e)}/>
  }

  const toggleStatus = async (e) => {
    await client.toggleResourceStatus(e.id)
      .then(() => {
        setChanged(changed + 1);
      })
      .catch((error) => notification.warning(error.message));

    return e.status.toLowerCase();
  }

  const handleDelete = async (e) => {
    if (confirm("Delete resource?")) {
      await client.deleteResource(e.id)
        .then(() => {
          setChanged(changed + 1);
        })
        .catch((error) => notification.warning(error.message));
    }
  }

  const renderActions = (e) => {
    return (
      <>
        <Tooltip title="Delete" aria-label="delete">
          <Delete className="action-icon" onClick={() => handleDelete(e)}/>
        </Tooltip>
      </>
    )
  }

  return (
    <div className="block">
      <div className="title">Resources</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{renderType(e)}</TableCell>
                <TableCell>{e.title}</TableCell>
                <TableCell className="resources-td-link">{renderLink(e)}</TableCell>
                <TableCell>{e.createdAt}</TableCell>
                <TableCell>{renderActions(e)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {TRANSLATIONS_TABS} from "./constants/translationsTabs";
import {TranslationsTable} from "./components/TranslationsTable/TranslationsTable";
import {AddTranslations} from "./tabs/AddTranslations/AddTranslations";
import {AddLang} from "./tabs/AddLang/AddLang";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";
import "./Translations.css";

export const Translations = () => {
  const {tab} = useParams();
  const history = useHistory();
  const [langs, setLangs] = useState();
  const [filterLocale, setFilterLocale] = useState(0);

  const getLangs = useCallback(async () => {
      await client.getAvailableLangs()
        .then(setLangs)
        .catch((error) => notification.warning(error.message));

  }, [history]);

  useEffect(() => {
    getLangs();
  }, [getLangs]);

  const handleChangeFilterLocale = (e) => {
    const locale = e.target.value;
    setFilterLocale(locale);
    if (locale !== "0") {
      history.push(ROUTE.TRANSLATIONS.replace(":tab", "all") + "?locale=" + locale);
    } else {
      history.push(ROUTE.TRANSLATIONS.replace(":tab", "all"));
    }
  }

  const renderPage = () => {
    if (tab === "all") {
      return <div>
        <TranslationsTable/>
      </div>
    }
    if (tab === "add_record") {
      return <div>
        <AddTranslations/>
      </div>
    }
    if (tab === "add_lang") {
      return <div>
        <AddLang/>
      </div>
    }
  }

  return (
    <div>
      <div className="search-panel">
        <Tabs tabs={TRANSLATIONS_TABS} route={ROUTE.TRANSLATIONS}/>

        {langs && <select
          id="locale"
          value={filterLocale && filterLocale}
          onChange={handleChangeFilterLocale}
          className="translations-filter"
        >
          <option key={0} value={0}>All</option>
          {langs.map((l, i) => (
            <option key={i} value={l.locale}>{l.locale} ({l.name})</option>
          ))}
        </select>}
      </div>

      {renderPage()}
    </div>
  )
}
import React, {useEffect, useState} from "react";
import "./UserStats.css";
import {ROUTE} from "../../../../../../../constants/routes";
import {Link} from "react-router-dom";
import {client} from "../../../../../../../services/client";
import {notification} from "../../../../../../../components/Notification";
import {toEur} from "../../../../../../../utils/tools";
import {useDispatch, useSelector} from "react-redux";
import {setUser, setUserDetails} from "../../../../../../../store/actions/actions";

export const UserStats = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.userDetails);
  const [packs, setPacks] = useState();
  const [wallet, setWallet] = useState();
  const [bonusStat, setBonusStat] = useState();

  const getWallet = async () => {
    user && await client.getWallet(user.id)
      .then(setWallet)
      .catch((error) => notification.warning(error.message));
  }

  const getBonusStat = async () => {
    user && await client.getBonusStat(user.id)
      .then(setBonusStat)
      .catch((error) => notification.warning(error.message));
  }

  const getPacks = async () => {
    await client.getPacks()
      .then(setPacks)
      .catch((error) => notification.warning(error.message));
  }

  useEffect(() => {
    getWallet();
    getBonusStat();
    getPacks();
  }, [user]);

  const reloadUser = async () => {
    await client.getUserById(user.id)
      .then((r) => {
        dispatch(setUserDetails(r));
      })
      .catch((error) => notification.warning(error.message));
  }

  const handleChangePack = async (e) => {
    const packId = e.target.value;
    if (packId < user.userPack.pack.id) {
      notification.info("Only upgrade package is available")
      return;
    }
    if (user.userPack.pack.id !== packId) {
      await client.changePack(user.id, packId)
        .then(() => {
          notification.success("Package has been changed");
          reloadUser();
        })
        .catch((error) => notification.warning(error.message));
    }
  }

  return (
    <div className="block user-stats">
      <div className="title">Stats</div>
      <table className="user-stats-table">
        <tbody>
        <tr>
          <td>Joined:</td>
          <td>{user?.createdAt}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td>{user?.status.toLowerCase()}</td>
        </tr>
        <tr>
          <td>Sponsor:</td>
          <td><Link to={ROUTE.USER_DETAILS.replace(":id", user?.refId).replace(":tab", "info")}>{user?.refId}</Link>
          </td>
        </tr>
        <tr>
          <td>Rank:</td>
          <td>{user?.userRank?.rank?.name}</td>
        </tr>
        <tr>
          <td>Active until:</td>
          <td>{user?.activeUntil}</td>
        </tr>
        <tr>
          <td>Pack level:</td>
          <td>
            {packs && <select
              id="pack"
              value={user?.userPack?.pack?.id}
              onChange={handleChangePack}
            >
              {packs.map((v, i) => {
                return <option key={i} value={v.id}>{v.name}</option>
              })}
            </select>}
          </td>
        </tr>
        <tr>
          <td>Cash wallet:</td>
          <td>{wallet && toEur(wallet.cashBalance)}</td>
        </tr>
        <tr>
          <td>Trading wallet:</td>
          <td>{wallet && toEur(wallet.tradingBalance)}</td>
        </tr>
        <tr>
          <td>Mandatory wallet:</td>
          <td>{wallet && toEur(wallet.mandatoryBalance)}</td>
        </tr>
        <tr>
          <td>Debt:</td>
          <td>{wallet && toEur(wallet.debt)}</td>
        </tr>
        <tr>
          <td>Vault tokens:</td>
          <td>{wallet && wallet.vaultTokens}</td>
        </tr>
        <tr>
          <td>Free tokens:</td>
          <td>{wallet && wallet.freeTokens}</td>
        </tr>
        <tr>
          <td>LMGT:</td>
          <td>{wallet && wallet.lmgtBalance}</td>
        </tr>
        <tr>
          <td>LRT:</td>
          <td>{wallet && wallet.lrtBalance}</td>
        </tr>
        <tr>
          <td>Direct sales:</td>
          <td>{bonusStat && toEur(bonusStat.directSales)}</td>
        </tr>
        <tr>
          <td>Binary bonus:</td>
          <td>{bonusStat && toEur(bonusStat.binaryBonus)}</td>
        </tr>
        <tr>
          <td>Manual binary bonus:</td>
          <td>{bonusStat && toEur(bonusStat.manualBinaryBonus)}</td>
        </tr>
        <tr>
          <td>Matching bonus:</td>
          <td>{bonusStat && toEur(bonusStat.matchingBonus)}</td>
        </tr>
        <tr>
          <td>Rank advance bonus:</td>
          <td>{bonusStat && toEur(bonusStat.rankBonus)}</td>
        </tr>
        <tr>
          <td>Life style bonus:</td>
          <td>{bonusStat && toEur(bonusStat.lsBonus)}</td>
        </tr>
        <tr>
          <td>Total earned:</td>
          <td>{wallet && toEur(wallet.earned)}</td>
        </tr>
        <tr>
          <td>Total withdrawn:</td>
          <td>{wallet && toEur(wallet.withdrawn)}</td>
        </tr>
        <tr>
          <td>PR balance:</td>
          <td>{wallet && wallet.prBalance}</td>
        </tr>
        <tr>
          <td>Education time:</td>
          <td>{wallet && wallet.timeBalance} min</td>
        </tr>

        </tbody>
      </table>
    </div>
  )
}
import React from "react";
import {NewsTable} from "./components/NewsTable";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {NEWS_TABS} from "./constants/newsTabs";

export const News = () => {
  return (
    <>
      <Tabs tabs={NEWS_TABS} route={ROUTE.NEWS_CREATE}/>
      <NewsTable/>
    </>
  )
}
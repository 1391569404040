import React from 'react';

import { SvgIcon } from './SvgIcon';

export const IconHamburger = props => (
  <SvgIcon {...props}>
    <path
      d="M4 18H20M4 6H20H4ZM4 12H20H4Z"
      stroke="#C2CFE0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {USER_TABS} from "./constants/userTabs";
import {UserInfo} from "./components/UserInfo/UserInfo";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {UserStatements} from "./components/Statements/UserStatements";
import {UserTransactions} from "./components/UserTransactions/UserTransactions";
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../../store/actions/actions";
import {UserLinks} from "./components/UserLinks/UserLinks";

export const UserDetails = () => {
  const {id} = useParams();
  const {tab} = useParams();
  const [user, setUser] = useState();
  const dispatch = useDispatch();

  const getUser = async () => {
    await client.getUserById(id)
      .then((r) => {
        setUser(r);
        dispatch(setUserDetails(r));
      })
      .catch((error) => notification.warning(error.message));
  }

  useEffect(() => {
    getUser();
  }, [id]);

  const renderPage = () => {
    if (tab === "info") {
      return <div>
        <UserInfo/>
      </div>
    }
    if (tab === "statements") {
      return <div>
        <UserStatements/>
      </div>
    }
    if (tab === "transactions") {
      return <div>
        <UserTransactions/>
      </div>
    }
    if (tab === "links") {
      return <div>
        <UserLinks/>
      </div>
    }
  }

  return (
    <div>
      <Tabs tabs={USER_TABS} route={ROUTE.USER_DETAILS}/>

      {user && renderPage()}
    </div>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import "./UserStatements.css";
import {Link, useParams} from "react-router-dom";
import {client} from "../../../../../services/client";
import {ROUTE} from "../../../../../constants/routes";
import {BonusRunTable} from "./components/BonusRunTable/BonusRunTable";
import {notification} from "../../../../../components/Notification";

export const UserStatements = () => {
  const {id} = useParams();
  const [user, setUser] = useState();
  const [userStat, setUserStat] = useState();

  const getUser = useCallback(async () => {
    await client.getUserById(id).then(setUser).catch((error) => notification.warning(error.message));
  }, []);

  const getUserStat = useCallback(async () => {
    await client.getUserStat(id).then(setUserStat).catch((error) => notification.warning(error.message));
  }, [id]);

  useEffect(() => {
    getUser();
    getUserStat();
  }, [])

  return (
    <div className="user-statements">
      <div className="block">
        <div className="user-statements-main-info">
          <table>
            <tbody>
            <tr>
              <td>UID:</td>
              <td>{user?.id}</td>
            </tr>
            <tr>
              <td>Ref:</td>
              <td><Link to={ROUTE.USER_DETAILS.replace(":id", id).replace(":tab", "info")}>{user?.refId}</Link></td>
            </tr>
            <tr>
              <td>Active until:</td>
              <td>{user?.activeUntil}</td>
            </tr>
            </tbody>
          </table>

          <table>
            <tbody>
            <tr>
              <td>Pack level:</td>
              <td>{user?.userPack?.pack?.name}</td>
            </tr>
            <tr>
              <td>Next title to reach:</td>
              <td>{userStat?.nextRank}</td>
            </tr>
            <tr>
              <td>Team or personally invited paid:</td>
              <td>left: {userStat?.personallyInvitedLeft}, right: {userStat?.personallyInvitedRight}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div className="user-statements-blocks-container">
          <div>
            <div className="user-statements-table">
              <div>
                <div className="user-statements-table-title">Progress of promotion</div>
                <div>Personal CV:</div>
                <div>Lesser volume leg CV:</div>
                <div>Personally invited paid:</div>
                <div>Highest F1 ranked:</div>
              </div>
              <div>
                <div className="user-statements-table-title">Current</div>
                {userStat ? <>
                  <div>{userStat?.currentPersonalCV}</div>
                  <div>{userStat?.currentLesserVolLegCV}</div>
                  <div>{userStat?.currentInvited}</div>
                  <div>{userStat?.currentHighestF1Rank}</div>
                </> : <span className="loader"/>}
              </div>
              <div>
                <div className="user-statements-table-title">Required</div>
                <div>{userStat?.requiredPersonalCV}</div>
                <div>{userStat?.requiredLesserVolLegCV}</div>
                <div>{userStat?.requiredInvited}</div>
                <div>{userStat?.requiredHighestF1Rank}</div>
              </div>

            </div>
          </div>

          <div>
            <div className="user-statements-table">
              <div>
                <div className="user-statements-table-title">Acumulated & expiring CV</div>
                <div>Accumulated last 12-months:</div>
                <div>New CV current week:</div>
                <div>Expiring:</div>
                <div>Expired:</div>
              </div>
              <div>
                <div className="user-statements-table-title">Left leg</div>
                {userStat ? <>
                  <div>{userStat?.leftAccumulatedLast12MonthCV}</div>
                  <div>{userStat?.leftCV}</div>
                  <div>{userStat?.leftExpiringCV}</div>
                  <div>0</div>
                </> : <span className="loader"/>}
              </div>
              <div>
                <div className="user-statements-table-title">Right leg</div>
                <div>{userStat?.rightAccumulatedLast12MonthCV}</div>
                <div>{userStat?.rightCV}</div>
                <div>{userStat?.rightExpiringCV}</div>
                <div>0</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        {user && <BonusRunTable userId={user.id}/>}
      </div>
    </div>
  )
}
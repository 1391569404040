import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import "../../Pack.css";

export const PackEdit = () => {
  const {id} = useParams();
  const [loadingUpdateForm, setLoadingUpdateForm] = useState(false);
  const [pack, setPack] = useState();

  useEffect(() => {
    client.getAdminDataPacks()
      .then((r) => {
        setPack(r.content.filter((e) => e.id === +id)[0])
      })
      .catch((error) => notification.warning(error.message))
  }, [id]);

  const updatePack = async (e) => {
    e.preventDefault();
    setLoadingUpdateForm(true);
    await client.updatePack(pack.id, pack)
      .then(() => notification.success("Pack updated"))
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingUpdateForm(false));
  }

  return (
    <div className="block pack-edit">
      <div className="title">Edit pack </div>

      <form onSubmit={updatePack}>
        <div>
          <div>Name</div>
          <input
            type="text"
            name="username"
            value={pack?.name}
            onChange={(v) => setPack({...pack, name: v.target.value})}
          />
        </div>
        <div>
          <div>Signup price</div>
          <input
            type="text"
            name="signupPrice"
            value={pack?.signupPrice}
            onChange={(v) => setPack({...pack, signupPrice: v.target.value})}
          />
        </div>
        <div>
          <div>Upgrade price</div>
          <input
            type="text"
            name="upgradePrice"
            value={pack?.upgradePrice}
            onChange={(v) => setPack({...pack, upgradePrice: v.target.value})}
          />
        </div>
        <div>
          <div>CV</div>
          <input
            type="text"
            name="cv"
            value={pack?.cv}
            onChange={(v) => setPack({...pack, cv: v.target.value})}
          />
        </div>
        <div>
          <div>PR</div>
          <input
            type="text"
            name="pr"
            value={pack?.pr}
            onChange={(v) => setPack({...pack, pr: v.target.value})}
          />
        </div>
        <div>
          <div>LMGT</div>
          <input
            type="text"
            name="lmgt"
            value={pack?.lmgt}
            onChange={(v) => setPack({...pack, lmgt: v.target.value})}
          />
        </div>
        <div>
          <div>Education time</div>
          <input
            type="text"
            name="educationTime"
            value={pack?.educationTime}
            onChange={(v) => setPack({...pack, educationTime: v.target.value})}
          />
        </div>
        <div>
          <div>Bonus cash alloc %</div>
          <input
            type="text"
            name="bonusAllocCash"
            value={pack?.bonusAllocCash}
            onChange={(v) => setPack({...pack, bonusAllocCash: v.target.value})}
          />
        </div>
        <div>
          <div>Bonus trading alloc %</div>
          <input
            type="text"
            name="bonusAllocTrading"
            value={pack?.bonusAllocTrading}
            onChange={(v) => setPack({...pack, bonusAllocTrading: v.target.value})}
          />
        </div>
        <div>
          <div>Bonus LMGT alloc %</div>
          <input
            type="text"
            name="bonusAllocLmgt"
            value={pack?.bonusAllocLmgt}
            onChange={(v) => setPack({...pack, bonusAllocLmgt: v.target.value})}
          />
        </div>
        <div>
          <div>Bonus mandatory alloc %</div>
          <input
            type="text"
            name="bonusAllocMandatory"
            value={pack?.bonusAllocMandatory}
            onChange={(v) => setPack({...pack, bonusAllocMandatory: v.target.value})}
          />
        </div>
        <div>
          <div>Sales exchange cash alloc %</div>
          <input
            type="text"
            name="salesExAllocCash"
            value={pack?.salesExAllocCash}
            onChange={(v) => setPack({...pack, salesExAllocCash: v.target.value})}
          />
        </div>
        <div>
          <div>Sales exchange mandatory alloc %</div>
          <input
            type="text"
            name="salesExAllocMandatory"
            value={pack?.salesExAllocMandatory}
            onChange={(v) => setPack({...pack, salesExAllocMandatory: v.target.value})}
          />
        </div>
        <div>
          <div>LMGT sell limit</div>
          <input
            type="text"
            name="lmgtSellLimit"
            value={pack?.lmgtSellLimit}
            onChange={(v) => setPack({...pack, lmgtSellLimit: v.target.value})}
          />
        </div>
        <div>
          <div>LMGT sell limit tag</div>
          <input
            type="text"
            name="lmgtSellLimitTag"
            value={pack?.lmgtSellLimitTag}
            onChange={(v) => setPack({...pack, lmgtSellLimitTag: v.target.value})}
          />
        </div>
        <div className="user-edit-form-button-container">
          {loadingUpdateForm ? <span className="loader"/> :
            <button className="button-green" type="submit">Save</button>}
        </div>
      </form>
    </div>
  )
}
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {CustomTree} from "../../../../../components/CustomTree/CustomTree";

export const Tree = () => {
  const {id} = useParams()
  const [value, setValue] = useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      <div className="content">
        <div className="dashboard-blocks-container">
          <div className="dashboard-block tree-block">
            <div className="dashboard-block-title">Sponsor tree</div>
            {id && <CustomTree type="sponsor" root={id}/>}
          </div>
        </div>
        <div className="dashboard-blocks-container">
          <div className="dashboard-block tree-block">
            <div className="dashboard-block-title">Binary tree</div>
            {id && <CustomTree type="binary" root={id}/>}
          </div>
        </div>
      </div>
    </>
  )
}
import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {REPORTS_TABS} from "./constants/reportsTabs";
import {Financial} from "./components/Financial/Financial";
import {useParams} from "react-router-dom";
import {PackHolders} from "./components/PackHolders/PackHolders";
import {Csv} from "./components/Csv/Csv";

export const Reports = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "financial") {
      return <Financial/>
    }
    if (tab === "pack-holders") {
      return <PackHolders/>
    }
    if (tab === "csv") {
      return <Csv/>
    }
  }

  return (
    <>
      <Tabs tabs={REPORTS_TABS} route={ROUTE.REPORTS}/>

      {renderContent()}
    </>
  )
}
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import "./News.css";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {ROUTE} from "../../../constants/routes";
import {Tabs} from "../../../components/Tabs/Tabs";
import {NEWS_TABS} from "./constants/newsTabs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const NewsCreate = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const history = useHistory();

  const modules = {
    toolbar: [
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'color': ['#FFFFFF', '#000000', '#323636', '#6940F5', '#007EC3']}],
      [{'background': ['#FFFFFF', '#000000', '#323636', '#6940F5', '#007EC3']}],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['code'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background',
    'code'
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await client.createNews({
        title: e.target.title.value,
        content: content
      });
      setLoading(false);
      history.push(ROUTE.NEWS);
    } catch (error) {
      notification.warning(error.message);
    }
    setLoading(false);
  }

  return (
    <>
      <div className="block">
        <div className="title">Create News</div>
        <form onSubmit={handleSubmit} className="news-form">
          <div>
            <div>Title</div>
            <input
              id="title"
              name="title"
              type="text"
              required
              autoComplete="off"
            />
          </div>
          <div className="form-item">
            <div>Content</div>
            <ReactQuill
              id="content"
              name="content"
              theme="snow"
              value={content}
              onChange={(v) => setContent(v)}
              modules={modules}
              formats={formats}
            />
          </div>

          <div className="news-form-button-container">
            {loading ? <span className="loader"/> :
              <button type="submit" className="button-green">Save</button>}
          </div>
        </form>
      </div>
    </>
  )
}
import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {EducationTable} from "./components/EducationTable/EducationTable";
import {EDUCATION_TABS} from "./constants/educationTabs";
import {useParams} from "react-router-dom";
import {EducationCreate} from "./EducationCreate";

export const Education = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "all") {
      return <EducationTable/>
    }
    if (tab === "create") {
      return <EducationCreate/>
    }
  }

  return (
    <>
      <Tabs tabs={EDUCATION_TABS} route={ROUTE.EDUCATION}/>

      {renderContent()}
    </>
  )
}
import React, {useState} from "react";
import "./Search.css";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../../../constants/routes";

export const Search = () => {
  const history = useHistory();
  const [data, setData] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    const p = history.location.pathname.split("/", 3)[2];
    if (p === "users") {
      history.push(ROUTE.USERS.replace(":tab", "all") + "?text=" + data);
    }
    if (p === "translations") {
      history.push(ROUTE.TRANSLATIONS.replace(":tab", "all") + "?text=" + data);
    }
  }

  return(
    <form className="search-container" onSubmit={handleSearch}>
      <input
        type="text"
        name="search"
        autoComplete="off"
        value={data}
        onChange={(e) => setData(e.target.value)}
      />

      <button type="submit" className="button-blue">Search</button>
    </form>
  )
}
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import "./Education.css";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";
import {ROUTE} from "../../../constants/routes";

export const EducationCreate = () => {
  const [loading, setLoading] = useState(false);
  const [startAt, setStartAt] = useState();
  const [title, setTitle] = useState();
  const [time, setTime] = useState();
  const [link, setLink] = useState();
  const history = useHistory();

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeTime = (e) => {
    setTime(e.target.value);
  };

  const handleChangeLink = (e) => {
    setLink(e.target.value);
  };

  const handleChangeStartAt = (e) => {
    setStartAt(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await client.createEducationTime({
        startAt: startAt,
        title: title,
        time: time,
        link: link,
      });
      setLoading(false);
      history.push(ROUTE.EDUCATION.replace(":tab", "all"));
    } catch (error) {
      notification.warning(error.message);
    }
    setLoading(false);
  }

  return (
    <>

      <div className="block">
        <div className="title">Create education link</div>

        <form onSubmit={handleSubmit} className="education-form">
          <div className="form-item">
            <div className="input-label">Title</div>
            <input
              id="title"
              name="title"
              type="text"
              required
              autoComplete="off"
              placeholder="Title"
              value={title}
              onChange={handleChangeTitle}
            />
          </div>
          <div className="form-item">
            <div className="input-label">Time</div>
            <input
              id="time"
              name="time"
              type="text"
              required
              autoComplete="off"
              placeholder="Time"
              value={time}
              onChange={handleChangeTime}
            />
          </div>
          <div className="form-item">
            <div className="input-label">Link</div>
            <input
              id="link"
              name="link"
              type="text"
              required
              autoComplete="off"
              placeholder="Link"
              value={link}
              onChange={handleChangeLink}
            />
          </div>
          <div className="form-item">
            <div className="input-label">Start At (format: YYYY-MM-DD h:i:s)</div>
            <input
              id="startAt"
              name="startAt"
              type="text"
              required
              autoComplete="off"
              placeholder="2023-01-01 10:00:00"
              value={startAt}
              onChange={handleChangeStartAt}
            />
          </div>

          <div className="education-form-button-container">
            {loading ? <span className="loader"/> :
              <button type="submit" className="button-green">Submit</button>}
          </div>
        </form>
      </div>
    </>
  )
}
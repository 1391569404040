import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {client} from "../../../../../services/client";
import {searchParamsToObject, toEur} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {ROUTE} from "../../../../../constants/routes";

export const MembershipPacksTable = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [updated, setUpdated] = useState(0);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'name', value: 'Name', direction: 'asc', sorted: true},
    {name: 'signupPrice', value: 'Signup price', direction: 'asc', sorted: true},
    {name: 'cv', value: 'CV', direction: 'asc', sorted: true},
    {name: 'pr', value: 'PR', direction: 'asc', sorted: true},
    {name: 'educationTime', value: 'Education time', direction: 'asc', sorted: true},
    {name: 'bonusAllocCash', value: 'Bonus alloc cash %', direction: 'asc', sorted: true},
    {name: 'bonusAllocMandatory', value: 'Bonus alloc mandatory %', direction: 'asc', sorted: true},
    {name: 'salesExAllocCash', value: 'Sales ex alloc cash %', direction: 'asc', sorted: true},
    {name: 'salesExAllocMandatory', value: 'Sales ex alloc mandatory %', direction: 'asc', sorted: true},
    {name: 'limitSellLmgt', value: 'Limit sell LMGT', direction: 'asc', sorted: true},
    {name: 'limitSellLmgtTag', value: 'Limit sell LMGT tag', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'actions', value: '', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getAdminDataPacks({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, updated]);


  const renderStatus = (e) => {
    if (e.status === "active") {
      return <span className="td-label td-label-green">{e.status.toLowerCase()}</span>
    } else if (e.status === "inactive") {
      return <span className="td-label td-label-red">{e.status.toLowerCase()}</span>
    } else {
      return <span>{e.status.toLowerCase()}</span>
    }
  }

  const renderPack = (e) => {
    if (e.pack) {
      return (
        <div className="pack">
          <img src={e.pack.logo}/>
          <div>{e.pack.name}</div>
        </div>
      )
    }
  }

  const renderActions = (pack) => {
    return (
      <>
        <Tooltip title="Edit" aria-label="edit">
          <Edit className="action-icon"
                onClick={() => history.push(ROUTE.PACKS_EDIT.replace(":id", pack.id).replace(":tab", "edit"))}/>
        </Tooltip>
      </>
    )
  }


  return (
    <div className="block">
      <div className="title">Packs</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{e.name}</TableCell>
                <TableCell>{toEur(e.signupPrice)}</TableCell>
                <TableCell>{toEur(e.cv, false)}</TableCell>
                <TableCell>{toEur(e.pr, false)}</TableCell>
                <TableCell>{e.educationTime}</TableCell>
                <TableCell>{e.bonusAllocCash}</TableCell>
                <TableCell>{e.bonusAllocMandatory}</TableCell>
                <TableCell>{e.salesExAllocCash}</TableCell>
                <TableCell>{e.salesExAllocMandatory}</TableCell>
                <TableCell>{e.lmgtSellLimit}</TableCell>
                <TableCell>{e.lmgtSellLimitTag}</TableCell>
                <TableCell>{renderStatus(e)}</TableCell>
                <TableCell>{renderActions(e)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
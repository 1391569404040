export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
    ALL: 'search/users',
    LS_ELIGIBLE: 'admin/users/ready-ls',
    GET_BY_ID: (id) => `search/users/${id}`,
    GET_USER_STAT: (id) => `admin/users/${id}/stat`,
    GET_BONUS_RUN_STAT: (id) => `bonuses/user/${id}/bonus-run`,
    GET_BONUS_STAT: (id) => `admin/users/${id}/bonuses/stat`,
    GET_LAST_WEEK_STAT: (id) => `cv/user/${id}/last-week`,
    UPDATE: (id) => `admin/users/${id}`,
    SPONSOR_SUBTREE: (id) => `users/sponsor-subtree/${id}`,
    BINARY_SUBTREE: (id) => `users/binary-subtree/${id}`,
    CHANGE_PACK: (userId, packId) => `admin/users/${userId}/pack/${packId}`,
    TOGGLE_TAG: (userId) => `admin/users/${userId}/tag`,
    TOGGLE_BAN: (userId) => `admin/users/${userId}/ban`,
    TOGGLE_PAYOUT: (userId) => `admin/users/${userId}/payout`,
    REFUND: (userId) => `admin/users/${userId}/refund`,
    MANUAL_BINARY_BONUS: 'bonuses/binary-bonus',
    LOGIN_HISTORY: 'admin/users/login-history',
  },

  WALLETS: {
    GET_BY_USER_ID: (userId) => `admin/wallets/user/${userId}`,
    TOPUP: 'admin/wallets/admin/topUp',
    WITHDRAW: 'admin/wallets/admin/withdraw',
    GET_LMGT_MAIN_BALANCE: 'admin/wallets/lmgt/mainbalance',
    GET_TOTAL_STAT: 'admin/wallets/stat/total',
    UPDATE_LRT_BALANCES: 'admin/wallets/updateLrtBalances',
  },

  ORDERS: {
    ALL: 'search/orders',
    GET_BY_ID: (id) => `search/orders/${id}`,
    CANCEL: (id) => `/admin/users/orders/${id}/cancel`,
  },

  EPINS: {
    ALL: 'search/epins',
    GET_BY_ID: (id) => `search/epins/${id}`,
  },

  TRANSACTIONS: {
    ALL: 'search/transactions',
    GET_BY_ID: (id) => `search/transactions/${id}`,
  },

  PAYOUTS: {
    GET_ALL: 'search/payouts',
    WITHDRAW_CRYPTO_API: 'payouts/cryptoApi/withdraw',
    APPROVE: (id) => `payouts/${id}/approve`,
    CANCEL: (id, userId) => `payouts/${id}/${userId}/cancel`,
  },

  STAT: {
    DASHBOARD: 'admin/stat/dashboard',
    PACK_HOLDERS: 'admin/stat/pack-holders/total',
    LRT_BALANCES: 'admin/stat/csv/lrt/balances',
    LRT_ORDERS: 'admin/stat/csv/lrt/orders',
  },

  PACKS: {
    GET_ALL: 'packs',
    GET_ADMIN_DATA: 'search/packs',
    GET_UPGRADE: 'packs/upgrade',
    UPDATE: (id) => `packs/${id}`,
  },

  LRT_PACKS: {
    GET_ALL: 'search/lrt-pack',
    GET_BY_ID: (id) => `search/token-pack/${id}`,
    UPDATE: (id) => `packs/token/${id}`,
  },

  NEWS: {
    GET_ALL: 'news',
    CREATE: 'news',
  },

  EDUCATION: {
    GET_ALL: 'search/education',
    CREATE: 'education',
  },

  STATEMENTS: {
    GET_ALL_BONUS_RUN: 'bonus-run/admin',
    GET_LAST_BONUS_RUN: 'bonuses/bonus-run',
    GET_LAST_CV_STAT: 'cv/last-week',
  },

  TRANSLATIONS: {
    ALL: 'search/translations',
    ADD_NEW_LANG: 'translations/addLang',
    ADD_NEW_KEY: 'translations/addKey',
    GET_AVAILABLE_LANGS: 'translations/langs',
    GET_BY_ID: (id) => `search/translations/${id}`,
    CHANGE_VALUE: (id) => `translations/change/value/${id}`,
  },

  REPORTS: {
    GET_ALL_WEEK_REPORTS: 'reports/weeks',
    GET_ALL_YEARS_REPORTS: 'reports/years',
    GET_ALL_DAYS_REPORTS_BY_ID: (id) => `reports/weeks/${id}/days`,
  },

  RESOURCES: {
    GET_ALL: 'search/resources',
    CREATE: 'resources',
    CREATE_VIDEO: 'resources/video',
    TOGGLE_STATUS: (id) => `resources/${id}/status`,
    DELETE: (id) => `resources/${id}`,
  },

  EVENTS: {
    GET_ALL: 'search/events',
    CREATE: 'events',
    TOGGLE_STATUS: (id) => `events/${id}/status`,
  },

  EXCHANGE: {
    GET_ALL_ORDERS: 'search/exchange/orders',
    GET_ALL_TRANSACTIONS: 'search/exchange/transactions',
    GET_TRANSACTIONS_BY_ORDER: (id) => `search/exchange/order/execute/${id}`,
    EXECUTE: (id) => `exchange/order/execute/${id}`,
  },

  VAULTS: {
    GET_ALL_VAULTS: 'search/vaults',
    GET_ALL_VAULT_TRANSACTIONS: 'search/vaults/transaction',
    GET_ALL_VAULT_WITHDRAWALS: 'search/vaults/withdraw',
    EXECUTE: (id) => `vault/withdraw/${id}/perform`,
  },

  LOYALTY: {
    GET_ALL: 'search/loyalty/reward',
  },

  SETTINGS: {
    ALL: 'search/settings',
    GET_BY_ID: (id) => `search/settings/${id}`,
    UPDATE: (id) => `settings/${id}`,
  },

  BONUSES: {
    RUN_PROFIT_SHARING: 'bonuses/profit-sharing',
  },
};

import {handleActions} from 'redux-actions'
import {LOG_OUT, SET_AUTH, SET_USER, SET_USER_DETAILS, SET_WALLET, SET_MODAL_STATES, SET_SUBMENU_ID, SET_MENU_ID, SET_USER_STAT, SET_MAP_STAT, SET_VAULT_DATA, SET_VAULT_DATA_CHANGES_COUNT, SET_SIDEBAR} from '../actions/actionTypes'

const initialState = {
  isAuth: false,
  user: null,
  userDetails: null,
  wallet: null,
  modalStates: {
    "modalInvite": false,
    "modalResetPassword": false,
    "modalSendEpin": false,
    "modalSecurityCode": false,
    "modalChangeEmail": false,
    "modalChangeUsername": false,
    "modalChangeWallet": false,
    "modalActivateVault": false,
    "modalEducationTime": false,
  },
  submenuId: 1,
  menuId: 1,
  userStat: null,
  mapStat: null,
  vaultData: null,
  setVaultDataChangesCount: 0,
  sidebar: false
}

export const baseReducer = handleActions(
  {
    SET_AUTH: (state, {payload}) => {
      return {
        ...state,
        isAuth: payload,
      }
    },
    LOG_OUT: () => initialState,
    SET_USER: (state, {payload}) => ({
      ...state,
      user: payload,
    }),
    SET_USER_DETAILS: (state, {payload}) => ({
      ...state,
      userDetails: payload,
    }),
    SET_WALLET: (state, {payload}) => ({
      ...state,
      wallet: payload,
    }),
    SET_MODAL_STATES: (state, {payload}) => ({
      ...state,
      modalStates: payload,
    }),
    SET_SUBMENU_ID: (state, {payload}) => ({
      ...state,
      submenuId: payload,
    }),
    SET_MENU_ID: (state, {payload}) => ({
      ...state,
      menuId: payload,
    }),
    SET_USER_STAT: (state, {payload}) => ({
      ...state,
      userStat: payload,
    }),
    SET_MAP_STAT: (state, {payload}) => ({
      ...state,
      mapStat: payload,
    }),
    SET_VAULT_DATA: (state, {payload}) => ({
      ...state,
      vaultData: payload,
    }),
    SET_VAULT_DATA_CHANGES_COUNT: (state, {payload}) => ({
      ...state,
      setVaultDataChangesCount: payload,
    }),
    SET_SIDEBAR: (state, {payload}) => ({
      ...state,
      sidebar: payload,
    })
  },
  initialState,
)

import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {useParams} from "react-router-dom";
import {ORDERS_TABS} from "./constants/ordersTabs";
import {OrdersTable} from "./components/OrdersTable/OrdersTable";

export const Orders = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "all") {
      return <OrdersTable/>
    }
  }
  return (
    <>
      <Tabs tabs={ORDERS_TABS} route={ROUTE.ORDERS}/>

      {renderContent()}
    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {client} from "../../../../../services/client";
import {searchParamsToObject} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";

export const EducationTable = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'title', value: 'Title', direction: 'asc', sorted: true},
    {name: 'time', value: 'Time', direction: 'asc', sorted: true},
    {name: 'startAt', value: 'Start At', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'link', value: 'Link', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getAllEducationTime({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage]);

  const renderStatus = (item) => {
    if (item.status === "ACTIVE") {
      return <span>{item.status.toLowerCase()}</span>
    } else {
      return <span>{item.status.toLowerCase()}</span>
    }
  }

  return (
    <div className="block">
      <div className="title">News</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{e.title}</TableCell>
                <TableCell>{e.timeAlloc}</TableCell>
                <TableCell>{e.date} {e.time} UTC</TableCell>
                <TableCell>{renderStatus(e)}</TableCell>
                <TableCell>{e.link}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
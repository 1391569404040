import React from "react";
import {ExchangeOrdersTable} from "./components/ExchangeOrdersTable/ExchangeOrdersTable";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {EXCHANGE_TABS} from "./constants/exchangeTabs";
import {useParams} from "react-router-dom";
import {ExchangeTransactionsTable} from "./components/ExchangeTransactionsTable/ExchangeTransactionsTable";

export const Exchange = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "orders") {
      return <ExchangeOrdersTable/>
    }
    if (tab === "transactions") {
      return <ExchangeTransactionsTable/>
    }
  }
  return (
    <>
      <Tabs tabs={EXCHANGE_TABS} route={ROUTE.EXCHANGE}/>

      {renderContent()}
    </>
  )
}
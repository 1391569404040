import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {client} from "../../../services/client";
import {setUser} from "../../../store/actions/actions";
import "./DashboardLayout.css";
import {TopPanel} from "../../TopPanel/TopPanel";
import {Sidebar} from "../../Sidebar/Sidebar";

export const DashboardLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);

  const fetchCurrentUser = useCallback(async () => {
    const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
    if (user === null && isAuth) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);



  useEffect(() => {
    fetchCurrentUser();
  }, []);


  return (
    <>
      <TopPanel/>
      <div className="container dashboard-layout">
        <Sidebar/>
        <div className="dashboard-content">
          {children}
        </div>
      </div>
    </>
  )
}
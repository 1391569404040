import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import {toEur} from "../../../../../utils/tools";

export const PackHolders = () => {
  const [data, setData] = useState();
  const [headerFields, setHeaderFields] = useState([
    {name: 'pack', value: 'Pack', direction: 'asc', sorted: true},
    {name: 'users', value: 'Users', direction: 'asc', sorted: true},
    {name: 'usersWithoutTag', value: 'Users not tagged', direction: 'asc', sorted: true},
    {name: 'tokens', value: 'LMGT', direction: 'asc', sorted: true},
    {name: 'tokensWithoutTag', value: 'LMGT from not tagged users', direction: 'asc', sorted: true},
  ]);

  const getData = useCallback(async () => {
    await client.getPackHolders()
      .then(setData)
      .catch((error) => notification.warning(error.message));
  }, [data]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="block">
      <div className="title">Pack holders</div>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.pack.name}</TableCell>
                <TableCell>{e.users}</TableCell>
                <TableCell>{e.usersWithoutTag}</TableCell>
                <TableCell>{toEur(e.tokens, false)}</TableCell>
                <TableCell>{toEur(e.tokensWithoutTag, false)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )

}
import React, {useState} from "react";
import "../../Translations.css";
import {client} from "../../../../../services/client";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../../../../constants/routes";
import {notification} from "../../../../../components/Notification";

export const AddLang = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    locale: "",
    name: ""
  });

  const add = async (e) => {
    e.preventDefault();
    if (data.locale.length < 2 || data.name.length < 2) {
      notification.warning("Please fill all fields");
      return;
    }
    setLoading(true);
    await client.addTranslationLang(data)
      .then(() => {
        history.push(ROUTE.TRANSLATIONS.replace(":tab", "all"));
        notification.success("New language added");
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }

  const changeField = (e) => {
    switch (e.target.name) {
      case "locale":
        setData({...data, locale: e.target.value});
        break;
      case "name":
        setData({...data, name: e.target.value});
        break;
    }
  }

  return (
    <div className="block">
      <div className="title">Add new language</div>

      <form className="translations-form" onSubmit={add}>
        <div>
          <div>Locale</div>
          <input
            type="text"
            name="locale"
            value={data.locale}
            onChange={changeField}
          />
        </div>

        <div>
          <div>Name</div>
          <input
            type="text"
            name="name"
            value={data.name}
            onChange={changeField}
          />
        </div>

        <div className="user-edit-form-button-container">
          {loading ? <span className="loader"/> :
            <button className="button-green" type="submit">Save</button>}
        </div>
      </form>
    </div>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {isMobile} from "../../../../../../../utils/checkDevice";
import {client} from "../../../../../../../services/client";
import {notification} from "../../../../../../../components/Notification";
import {toEur} from "../../../../../../../utils/tools";

export const BonusRunTable = ({userId}) => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);

  const [updateState, setUpdateState] = useState(0);

  const [headerFields, setHeaderFields] = useState(isMobile() ? [
      {name: 'id', value: 'ID', direction: 'asc', sorted: true},
      {name: 'rank', value: 'Rank', direction: 'asc', sorted: true},
      {name: 'pack', value: 'Pack', direction: 'asc', sorted: true},
      {name: 'cvLeft', value: 'CV L', direction: 'asc', sorted: true},
      {name: 'cvRight', value: 'CV R', direction: 'asc', sorted: true},
      {name: 'rolloverLeft', value: 'RO L', direction: 'asc', sorted: true},
      {name: 'rolloverRight', value: 'RO R', direction: 'asc', sorted: true},
      {name: 'rolloverLeftTotal', value: 'RO L total', direction: 'asc', sorted: true},
      {name: 'rolloverRightTotal', value: 'RO R total', direction: 'asc', sorted: true},
      {name: 'cvPaidLeft', value: 'CV paid L', direction: 'asc', sorted: true},
      {name: 'cvPaidRight', value: 'CV paid R', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Amount paid', direction: 'asc', sorted: true},
      {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    ] :
    [
      {name: 'id', value: 'ID', direction: 'asc', sorted: true},
      {name: 'rank', value: 'Rank', direction: 'asc', sorted: true},
      {name: 'pack', value: 'Pack', direction: 'asc', sorted: true},
      {name: 'cvLeft', value: 'CV L', direction: 'asc', sorted: true},
      {name: 'cvRight', value: 'CV R', direction: 'asc', sorted: true},
      {name: 'rolloverLeft', value: 'RO L', direction: 'asc', sorted: true},
      {name: 'rolloverRight', value: 'RO R', direction: 'asc', sorted: true},
      {name: 'rolloverLeftTotal', value: 'RO L total', direction: 'asc', sorted: true},
      {name: 'rolloverRightTotal', value: 'RO R total', direction: 'asc', sorted: true},
      {name: 'cvPaidLeft', value: 'CV paid L', direction: 'asc', sorted: true},
      {name: 'cvPaidRight', value: 'CV paid R', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Amount paid', direction: 'asc', sorted: true},
      {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    ]
  );

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getData = useCallback(async () => {
    try {
      const response = await client.getAllBonusRun({
        userId: userId,
        page: currentPage,
        size: 10,
      });
      setData(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [currentPage, updateState]);

  const renderActions = (e) => {
    return (
      <div id={e.id}>View</div>
    )
  }

  return (
    <>
      <Table className="table" size="small">
        <TableHead>
          <TableRow>
            {headerFields.map(f =>
              <TableCell key={f.name}>{f.value}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((e, index) => (
            isMobile() ?
              <TableRow hover key={index}>
                <TableCell>{e.externalId}</TableCell>
                <TableCell>{e.rank}</TableCell>
                <TableCell>{e.pack}</TableCell>
                <TableCell>{e.cvLeft}</TableCell>
                <TableCell>{e.cvRight}</TableCell>
                <TableCell>{e.rolloverLeft}</TableCell>
                <TableCell>{e.rolloverRight}</TableCell>
                <TableCell>{e.rolloverLeftTotal}</TableCell>
                <TableCell>{e.rolloverRightTotal}</TableCell>
                <TableCell>{e.cvPaidLeft}</TableCell>
                <TableCell>{e.cvPaidRight}</TableCell>
                <TableCell>{toEur(e.amountPaid)}</TableCell>
                <TableCell>{e.createdAt}</TableCell>
              </TableRow> :
              <TableRow hover key={index}>
                <TableCell>{e.externalId}</TableCell>
                <TableCell>{e.rank}</TableCell>
                <TableCell>{e.pack}</TableCell>
                <TableCell>{e.cvLeft}</TableCell>
                <TableCell>{e.cvRight}</TableCell>
                <TableCell>{e.rolloverLeft}</TableCell>
                <TableCell>{e.rolloverRight}</TableCell>
                <TableCell>{e.rolloverLeftTotal}</TableCell>
                <TableCell>{e.rolloverRightTotal}</TableCell>
                <TableCell>{e.cvPaidLeft}</TableCell>
                <TableCell>{e.cvPaidRight}</TableCell>
                <TableCell>{toEur(e.amountPaid)}</TableCell>
                <TableCell>{e.createdAt}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={10}
        rowsPerPageOptions={10}
        page={currentPage}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={onChangePage}
        className="table-pagination"
      />

    </>
  );
}
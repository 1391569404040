import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {client} from "../../../../../services/client";
import {searchParamsToObject, toEur} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";

export const UserTransactions = () => {
  const {id} = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'uid', value: 'UID', direction: 'asc', sorted: true},
    {name: 'initUID', value: 'Init UID', direction: 'asc', sorted: true},
    {name: 'original', value: 'Original', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'io', value: 'I/O', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'notes', value: 'Notes', direction: 'asc', sorted: true},
    {name: 'wallet', value: 'Wallet', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getAllTransactions({
      page: currentPage,
      size: rowsPerPage,
      userId: id,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage]);

  const renderIO = (e) => {
    return e.ioType === "input" ? <span className="td-label td-label-green">input</span> : <span className="td-label td-label-red">output</span>;
  }

  return (
    <div className="block">
      <div className="title">Transactions</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{e.userId}</TableCell>
                <TableCell>{e.initUserId}</TableCell>
                <TableCell>{toEur(e.originalAmount)}</TableCell>
                <TableCell>{toEur(e.amount)}</TableCell>
                <TableCell>{renderIO(e)}</TableCell>
                <TableCell>{e.type}</TableCell>
                <TableCell>{e.notes}</TableCell>
                <TableCell>{e.balanceType}</TableCell>
                <TableCell>{e.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
import React, {useEffect, useState} from "react";
import "./UserEdit.css";
import {Switch} from "@mui/material";
import {client} from "../../../../../../../services/client";
import {notification} from "../../../../../../../components/Notification";
import {WalletForm} from "../WalletForm/WalletForm";
import {useSelector} from "react-redux";

export const UserEdit = () => {
  const user = useSelector(store => store.base.userDetails)
  const [userData, setUserData] = useState();
  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const changeUserField = (e) => {
    switch (e.target.name) {
      case "username" :
        setUserData({...userData, username: e.target.value});
        break;
      case "email" :
        setUserData({...userData, email: e.target.value});
        break;
      case "password" :
        setUserData({...userData, password: e.target.value});
        break;
      case "phone" :
        setUserData({...userData, phone: e.target.value});
        break;
      case "specialNote" :
        setUserData({...userData, specialNote: e.target.value});
        break;
      // case "refId" :
      //   setUserData({...userData, refId: e.target.value});
      //   break;
    }
  }

  const updateUser = async (e) => {
    e.preventDefault();
    setLoadingUpdateUser(true);
    await client.updateUser(user.id, userData)
      .then(() => notification.success("User updated"))
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingUpdateUser(false));
  }

  const toggleTag = async () => {
    const oldVal = userData.tradingTag;
    await client.toggleTag(userData.id)
      .then(() => setUserData({...userData, tradingTag: !oldVal}))
      .catch((error) => notification.warning(error.message));
  }

  const toggleBan = async () => {
    const oldVal = userData.status === "BANNED";
    await client.toggleBan(userData.id)
      .then(() => setUserData({...userData, status: oldVal ? "MEMBER" : "BANNED"}))
      .catch((error) => notification.warning(error.message));
  }

  const togglePayout = async () => {
    const oldVal = userData.blockedPayout;
    await client.togglePayout(userData.id)
      .then(() => setUserData({...userData, blockedPayout: !oldVal}))
      .catch((error) => notification.warning(error.message));
  }

  const refund = async () => {
    setLoadingRefund(true);
    if (confirm(("Make refund?"))) {
      await client.refundUser(userData.id)
        .then(() => notification.success("Refunded"))
        .catch((error) => notification.warning(error.message));
    }
    setLoadingRefund(false);
  }


  return (
    <div className="block user-edit">
      <div className="title">Member info</div>

      <form className="user-edit-form" onSubmit={updateUser}>

        <div>
          <div>Username</div>
          <input
            type="text"
            name="username"
            value={userData?.username}
            onChange={changeUserField}
          />
        </div>

        <div>
          <div>Email</div>
          <input
            type="text"
            name="email"
            value={userData?.email}
            onChange={changeUserField}
          />
        </div>

        <div>
          <div>Password</div>
          <input
            type="password"
            name="password"
            value={userData?.password}
            onChange={changeUserField}
          />
        </div>

        <div>
          <div>Phone</div>
          <input
            type="text"
            name="phone"
            value={userData?.phone}
            onChange={changeUserField}
          />
        </div>

        <div>
          <div>Special note</div>
          <textarea
            name="specialNote"
            rows={5}
            value={userData?.specialNote}
            onChange={changeUserField}
          />
        </div>

        <div className="user-edit-form-button-container">
          {loadingUpdateUser ? <span className="loader"/> :
            <button className="button-green" type="submit">Update</button>}
        </div>
      </form>

      <div className="user-edit-form-switch-container">
        <div>
          <div>Trading tag</div>
          {userData && <Switch checked={userData.tradingTag} onChange={toggleTag}/>}
        </div>

        <div>
          <div>Disabled account</div>
          {userData && <Switch checked={userData.status === "BANNED"} onChange={toggleBan}/>}
        </div>

        <div>
          <div>Block payout</div>
          {userData && <Switch checked={userData.blockedPayout} onChange={togglePayout}/>}
        </div>
      </div>

      <WalletForm user={user}/>

      <div className="user-edit-additional">
        <h4>Additional functions</h4>
        {loadingRefund ? <span className="loader"/> :
          <button className="button-red" onClick={(e) => refund(user.id)}>Refund</button>}
      </div>

    </div>
  )
}
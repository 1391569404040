import React, {useEffect, useState} from "react";
import {client} from "../../../../../../../services/client";
import {searchParamsToObject} from "../../../../../../../utils/tools";
import {notification} from "../../../../../../../components/Notification";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

export const LoginHistory = () => {
  const user = useSelector(store => store.base.userDetails)
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const [headerFields, setHeaderFields] = useState([
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    {name: 'ip', value: 'IP', direction: 'asc', sorted: true},
    {name: 'country', value: 'Country', direction: 'asc', sorted: true},
    {name: 'city', value: 'City', direction: 'asc', sorted: true},
    {name: 'device', value: 'Device', direction: 'asc', sorted: true},
    {name: 'os', value: 'OS', direction: 'asc', sorted: true},
    {name: 'browser', value: 'Browser', direction: 'asc', sorted: true},
  ]);

  const getData = async () => {
    const _params = new URLSearchParams(history.location.search)
    user && await client.getLoginHistory({
      userId: user.id,
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
      setData(response.content);
      setTotal(response.totalElements);
    })
      .catch((error) => notification.warning(error.message));
  }

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, user]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  return(
    <div className="block">
      <div className="title">Login history</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.date}</TableCell>
                <TableCell>{e.ip}</TableCell>
                <TableCell>{e.country}</TableCell>
                <TableCell>{e.city}</TableCell>
                <TableCell>{e.device}</TableCell>
                <TableCell>{e.os}</TableCell>
                <TableCell>{e.browser}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
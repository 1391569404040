export const SET_AUTH = 'SET_AUTH';
export const LOG_OUT = 'LOG_OUT';
export const SET_USER = 'SET_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_WALLET = 'SET_WALLET';
export const SET_MODAL_STATES = 'SET_MODAL_STATES';
export const SET_SUBMENU_ID = 'SET_SUBMENU_ID';
export const SET_MENU_ID = 'SET_MENU_ID';
export const SET_USER_STAT = 'SET_USER_STAT';
export const SET_MAP_STAT = 'SET_MAP_STAT';
export const SET_VAULT_DATA = 'SET_VAULT_DATA';
export const SET_VAULT_DATA_CHANGES_COUNT = 'SET_VAULT_DATA_CHANGES_COUNT';
export const SET_SIDEBAR = 'SET_SIDEBAR';

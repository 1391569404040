import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {UserTable} from "./components/UserTable/UserTable";
import {USER_TABLE_TABS} from "./constants/userTableTabs";

export const Users = () => {

  const renderContent = () => {
    return <UserTable/>
  }
  return (
    <>
      <Tabs tabs={USER_TABLE_TABS} route={ROUTE.USERS}/>

      {renderContent()}
    </>
  )
}
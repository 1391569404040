import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import "../../Users.css";
import {isMobile} from "../../../../../utils/checkDevice";
import {searchParamsToObject} from "../../../../../utils/tools";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import {ROUTE} from "../../../../../constants/routes";

export const UserTable = () => {
  const {tab} = useParams();
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [users, setUsers] = useState();
  const history = useHistory();

  const [filterStatus, setFilterStatus] = useState(0);
  const [filterRole, setFilterRole] = useState(0);
  const [filterTradingTag, setFilterTradingTag] = useState(0);

  const [headerFields, setHeaderFields] = useState(isMobile() ? [
      {name: 'id', value: 'ID', direction: 'asc', sorted: true},
      {name: 'username', value: 'Username', direction: 'asc', sorted: true},
      {name: 'email', value: 'Email', direction: 'asc', sorted: true},
      {name: 'actions', value: '', direction: 'asc', sorted: true},
    ]
    : [
      {name: 'id', value: 'ID', direction: 'asc', sorted: true},
      {name: 'username', value: 'Username', direction: 'asc', sorted: true},
      {name: 'email', value: 'Email', direction: 'asc', sorted: true},
      {name: 'country', value: 'Country', direction: 'asc', sorted: true},
      {name: 'ref', value: 'Ref', direction: 'asc', sorted: true},
      {name: 'pack', value: 'Pack', direction: 'asc', sorted: true},
      {name: 'rank', value: 'Rank', direction: 'asc', sorted: true},
      {name: 'status', value: 'Status', direction: 'asc', sorted: true},
      {name: 'active', value: 'Active', direction: 'asc', sorted: true},
      {name: 'createdAt', value: 'Created', direction: 'asc', sorted: true},
      {name: 'actions', value: 'Actions', direction: 'asc', sorted: true},
    ]);

  const getUsers = useCallback(async () => {
    switch (tab) {
      case "all" : {
        try {
          const _params = new URLSearchParams(history.location.search)
          const response = await client.getAllUsers({
            page: page,
            size: rowsPerPage,
            ...searchParamsToObject(_params),
          })
          setUsers(response.content);
          setTotal(response.totalElements)

        } catch (error) {
          notification.warning(error.message);
        } finally {
        }
        break;
      }

      case "ls-eligible": {
        try {
          const _params = new URLSearchParams(history.location.search)
          const response = await client.getLSEligible({
            page: page,
            size: rowsPerPage,
            userId: 0,
            ...searchParamsToObject(_params),
          })
          setUsers(response.content);
          setTotal(response.totalElements)

        } catch (error) {
          notification.warning(error.message);
        } finally {
        }
        break;
      }
    }

  }, [page, rowsPerPage, history, tab]);

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage, tab]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname.split("/", 3)[2] === "users") {
        getUsers();
      } else {
        unlisten();
      }
    })
  }, [history]);


  const onChangePage = (_, newPage) => {
    setPage(newPage)
    // history.push(history.location.pathname + history.location.search + "?&page=" + newPage);
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };


  const renderActions = (user) => {
    return (
      <>
        <Tooltip title="Details" aria-label="edit">
          <Edit className="action-icon"
                onClick={() => history.push(ROUTE.USER_DETAILS.replace(":id", user.id).replace(":tab", "info"))}/>
        </Tooltip>
      </>
    )
  }

  const renderUserLink = (id) => {
    return (
      <Link to={ROUTE.USER_DETAILS.replace(":id", id).replace(":tab", "info")}>{id}</Link>
    )
  }

  const renderPack = (user) => {
    return (
      <div className="pack">
        <img src={user.userPack.pack.logo}/>
        <div>{user.userPack.pack.name}</div>
      </div>
    )
  }

  const renderStatus = (user) => {
    if (user.status === "NEW") {
      return <Tooltip title="New user, on payment form after login" aria-label="status-new">
        <span className="td-label td-label-green">{user.status.toLowerCase()}</span>
      </Tooltip>
    }
    if (user.status === "MEMBER") {
      return <Tooltip title="Full registered user with a pack" aria-label="status-member">
        <span className="td-label td-label-blue">{user.status.toLowerCase()}</span>
      </Tooltip>
    }
    if (user.status === "WAITING_FOR_PAYMENT") {
      return <Tooltip title="User chose a pack but did not pay" aria-label="status-waiting">
        <span className="td-label td-label-orange">pending</span>
      </Tooltip>
    }
    if (user.status === "BANNED") {
      return <Tooltip title="User banned by admin" aria-label="status-banned">
        <span className="td-label td-label-red">banned</span>
      </Tooltip>
    }
  }

  const renderActive = (user) => {
    return user.active ? <span className="td-label td-label-green">Yes</span> :
      <span className="td-label td-label-red">No</span>;
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params.get('status');
    const role = params.get('role');
    const tradingTag = params.get('tradingTag');
    if (status) {
      setFilterStatus(status);
    }
    if (role) {
      setFilterRole(role);
    }
    if (tradingTag) {
      setFilterTradingTag(tradingTag);
    }
  }, [history]);

  const generateLink = (excludedVarName) => {
    const params = new URLSearchParams(history.location.search)
    const status = params.get('status');
    const role = params.get('role');
    const tradingTag = params.get('tradingTag');

    let link = "?";
    if (status !== null && excludedVarName !== "status") {
      link = link + "&status=" + status;
    }
    if (role !== null && excludedVarName !== "role") {
      link = link + "&role=" + role;
    }
    if (tradingTag !== null && excludedVarName !== "tradingTag") {
      link = link + "&tradingTag=" + tradingTag;
    }
    return link;
  }

  const search = (e) => {
    e.preventDefault();
    const link = generateLink("");
    history.push(link + "&text=" + e.target.search.value);
    getUsers();
  }

  const handleChangeFilterStatus = (e) => {
    const status = e.target.value;
    const link = generateLink("status");
    setFilterStatus(status);
    if (status !== 0) {
      history.push(link + "&status=" + status);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleChangeFilterRole = (e) => {
    const role = e.target.value;
    const link = generateLink("role");
    setFilterRole(role);
    if (role !== 0) {
      history.push(link + "&role=" + role);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleChangeFilterTradingTag = (e) => {
    const tradingTag = e.target.value;
    const link = generateLink("tradingTag");
    setFilterTradingTag(tradingTag);
    if (tradingTag !== 0) {
      history.push(link + "&tradingTag=" + tradingTag);
    } else {
      history.push(link);
    }
    getUsers();
  }

  return (
    <div className="users">

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users && users.map((user, index) => (
              isMobile() ?
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{renderActions(user)}</TableCell>
                </TableRow> :
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.countryName}</TableCell>
                  <TableCell>{renderUserLink(user.refId)}</TableCell>
                  <TableCell>{user.userPack && renderPack(user)}</TableCell>
                  <TableCell>{user.userRank && user.userRank.rank.name}</TableCell>
                  <TableCell>{renderStatus(user)}</TableCell>
                  <TableCell>{renderActive(user)}</TableCell>
                  <TableCell>{user.createdAt}</TableCell>
                  <TableCell>{renderActions(user)}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <div className="reset-filters" onClick={() => history.push(ROUTE.USERS)}>Reset</div>
    </div>
  )
}
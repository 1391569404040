import React, {useState} from "react";
import {Paper} from "@mui/material";
import "./WithdrawCryptoApi.css";
import {notification} from "../../../../../components/Notification";
import {client} from "../../../../../services/client";

export const WithdrawCryptoApi = () => {
  const [currency, setCurrency] = useState("BTC");
  const [blockchain, setBlockchain] = useState("bitcoin");
  const [amount, setAmount] = useState();
  const [addressTo, setAddressTo] = useState("0x647dcd362687f1dd9cb01993774d6f4919305750");
  const [addressFrom, setAddressFrom] = useState();
  const [loading, setLoading] = useState(false);

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
  }

  const handleChangeBlockchain = (e) => {
    setBlockchain(e.target.value);
  }

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  }

  const handleChangeAddressTo = (e) => {
    setAddressTo(e.target.value);
  }

  const handleChangeAddressFrom = (e) => {
    setAddressFrom(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (loading) {
      return;
    }
    if (!amount && isNaN(amount) || amount < 0.0001 || !currency || !addressTo) {
      notification.warning("Wrong input params");
      return;
    }

    if (currency === "BTC" && blockchain !== "bitcoin") {
      notification.warning("Wrong input params for BTC");
      return;
    }

    if (currency === "ETH" && blockchain !== "ethereum") {
      notification.warning("Wrong input params for ETH");
      return;
    }

    setLoading(true);
    try {
      await client.withdrawCryptoApi({
        to: addressTo,
        from: addressFrom,
        amount: +amount,
        currency: currency,
        blockchain: blockchain
      });
      setAddressTo(null);
      setAddressFrom(null);
      setAmount(null);
    } catch (error) {
      notification.warning(error.message);
    }
    setLoading(false);
  }

  return (
    <Paper square className="crypto-api-form">
      <form onSubmit={handleSubmit}>
        <div>
          <div className="crypto-api-input-label">Address To:</div>
          <input
            id="addressTo"
            name="addressTo"
            type="text"
            autoComplete="off"
            value={addressTo}
            onChange={handleChangeAddressTo}
          />
        </div>

        <div>
          <div className="crypto-api-input-label">Address From (except BTC withdrawals):</div>
          <input
            id="addressFrom"
            name="addressFrom"
            type="text"
            autoComplete="off"
            value={addressFrom}
            onChange={handleChangeAddressFrom}
          />
        </div>

        <div>
          <div className="crypto-api-input-label">Amount</div>
          <input
            id="amount"
            name="amount"
            type="text"
            autoComplete="off"
            value={amount}
            onChange={handleChangeAmount}
          />
        </div>

        <div>
          <div className="crypto-api-input-label">Blockchain</div>
          <select
            id="blockchain"
            value={blockchain && blockchain}
            onChange={handleChangeBlockchain}
          >
            <option key={0} value={"bitcoin"}>Bitcoin</option>
            <option key={1} value={"ethereum"}>Ethereum</option>
            <option key={2} value={"binance-smart-chain"}>Binance Smart Chain</option>
          </select>
        </div>

        <div>
          <div className="crypto-api-input-label">Currency</div>
          <select
            id="currency"
            value={currency && currency}
            onChange={handleChangeCurrency}
          >
            <option key={0} value={"BTC"}>BTC</option>
            <option key={1} value={"ETH"}>ETH</option>
            <option key={2} value={"BNB"}>BNB</option>
            <option key={3} value={"USDT (ERC-20)"}>USDT (ERC-20)</option>
            <option key={4} value={"USDT (BEP-20)"}>USDT (BEP-20)</option>
          </select>
        </div>

        <div className="crypto-api-form-button-container">
          <button className="button-blue" type="submit">Submit</button>
        </div>

      </form>
    </Paper>
  )
}
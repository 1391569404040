import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {client} from "../../../../../services/client";
import {searchParamsToObject} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import {Done} from "@mui/icons-material";
import {ROUTE} from "../../../../../constants/routes";

export const VaultTable = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [updated, setUpdated] = useState(0);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'uid', value: 'UID', direction: 'asc', sorted: true},
    {name: 'pack', value: 'Pack', direction: 'asc', sorted: true},
    {name: 'totalAmount', value: 'Total Amount', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'created', value: 'Created', direction: 'asc', sorted: true},
    {name: 'Actions', value: 'Actions', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getAllVaults({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, updated]);


  const renderStatus = (e) => {
    if (e.status === "active") {
      return <span className="td-label td-label-blue">{e.status.toLowerCase()}</span>
    } else if (e.status === "closed") {
      return <span className="td-label td-label-green">{e.status.toLowerCase()}</span>
    } else {
      return <span>{e.status.toLowerCase()}</span>
    }
  }

  const renderActions = (e) => {
    return <div className="action-icon-container"><Tooltip title="Transactions" aria-label="transactions">
      <span className="action-icon" onClick={() =>
        history.push(ROUTE.VAULTS.replace(":tab", "transactions?vaultId=" + e.id))}>T</span>
    </Tooltip>
      <Tooltip title="Withdrawals" aria-label="withdrawals">
      <span className="action-icon" onClick={() =>
        history.push(ROUTE.VAULTS.replace(":tab", "withdrawals?vaultId=" + e.id))}>W</span>
      </Tooltip>
    </div>
  }

  return (
    <div className="block">
      <div className="title">Vaults</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{e.userId}</TableCell>
                <TableCell>{e.pack}</TableCell>
                <TableCell>{e.totalAmount}</TableCell>
                <TableCell>{e.type}</TableCell>
                <TableCell>{renderStatus(e)}</TableCell>
                <TableCell>{e.createdAt}</TableCell>
                <TableCell>{renderActions(e)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
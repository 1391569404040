import React, {useEffect, useState} from "react";
import "./Tabs.css";
import {useHistory, useParams} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";

export const Tabs = ({route, tabs}) => {
  const history = useHistory();
  const {id} = useParams();
  const {tab} = useParams();
  const [params, setParams] = useState();

  useEffect(() => {
    const _params = new URLSearchParams(history.location.search);
    setParams(searchParamsToObject(_params));
  }, [])

  return (
    <div className="tabs">
      {params && tabs.map((el, i) => {
        const userId = params.userId !== undefined ? "?userId=" + params.userId : "";
        return <div className={tab === el.name ? "tabs-item tabs-item-active" : "tabs-item"} key={i}
                    id={el.id}
                    onClick={() =>{
                      history.push(route
                        .replace(":id", id)
                        .replace(":tab", el.name)
                        + userId
                      )
                    }}>
          {el.title}
        </div>
      })}
    </div>
  )
}
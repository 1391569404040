import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";

export const SettingsEdit = () => {
  const {id} = useParams();
  const [loadingUpdateForm, setLoadingUpdateForm] = useState(false);
  const [data, setData] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    console.log(id)
    client.getSettingsById(id)
      .then((r) => {
        console.log(r)
        setData(r)
        setValue(r.paramValue)
      })
      .catch((error) => notification.warning(error.message))
  }, [id]);

  const update = async (e) => {
    e.preventDefault();
    setLoadingUpdateForm(true);
    await client.updateSettings(data.id, {
      value: value
    })
      .then(() => notification.success("Setting updated"))
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingUpdateForm(false));
  }

  return (
    <div className="block pack-edit">
      <div className="title">Edit setting id: {data?.id}, key: {data?.paramKey} </div>

      <form onSubmit={update}>
        <div>
          <div>Value</div>
          <input
            type="text"
            name="value"
            value={value}
            onChange={(v) => setValue(v.target.value)
            }
          />
        </div>

        <div className="user-edit-form-button-container">
          {loadingUpdateForm ? <span className="loader"/> :
            <button className="button-green" type="submit">Save</button>}
        </div>
      </form>
    </div>
  )
}
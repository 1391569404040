import React, {useState} from "react";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import "./Csv.css";

export const Csv = () => {
  const [loadingCsvBalances, setLoadingCsvBalances] = useState(false);
  const [loadingCsvOrders, setLoadingCsvOrders] = useState(false);
  const [loadingUpdateLrt, setLoadingUpdateLrt] = useState(false);
  const [loadingUploadCsv, setLoadingUploadCsv] = useState(false);
  const [file, setFile] = useState(null);

  const getUtcDate = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1;
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();
    return year + "" + month + "" + day + "_" + hours + "" + minutes + "" + seconds;
  }

  const getCsvWithLrtBalances = async () => {
    setLoadingCsvBalances(true)
    await client.getCsvWithLrtBalances()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const fn = "bt_lrt_balances_" + getUtcDate() + ".csv";
        link.setAttribute('download', fn);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingCsvBalances(false));
  }

  const getCsvWithLrtOrders = async () => {
    setLoadingCsvOrders(true)
    await client.getCsvWithLrtOrders()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const fn = "bt_lrt_orders_" + getUtcDate() + ".csv";
        link.setAttribute('download', fn); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingCsvOrders(false));
  }

  const updateLrt = async () => {
    setLoadingUpdateLrt(true);
    await client.updateLrtBalances()
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingUpdateLrt(false));
  }

  const runProfitSharing = async () => {
    setLoadingUploadCsv(true);

    const formData = new FormData();
    formData.append("file", file)
    await client.runProfitSharing(formData)
      .catch((error) => notification.warning(error.message))
      .then(() => notification.success("Profit sharing bonus ran successfully"))
      .finally(() => {
        setLoadingUploadCsv(false);
        setFile(null);
      });
  }


  return (
    <div className="block">
      <div className="title">CSV reports</div>
      <div>LRT balances are in Multichain. To generate CSV reports runtime will cost us 6K+ requests to the Multichain.
        Server or Mutlichain might go down if during this time some other statistics is calculating in database.
        To avoid that kind of issues we have Multichain synchronization with our DB. It runs everyday at 6:10 UTC. But
        if you need updated LRT balances just now, please go bellow to the danger zone.
      </div>
      <div className="csv-container">
        <div>
          {loadingCsvBalances ? <span className="loader"/> :
            <button className="button-blue" onClick={getCsvWithLrtBalances}>Download LRT balances</button>}
        </div>
        <div>
          {loadingCsvOrders ? <span className="loader"/> :
            <button className="button-blue" onClick={getCsvWithLrtOrders}>Download LRT orders</button>}
        </div>
      </div>

      <div className="csv-danger-zone">
        <h3>Danger zone</h3>
        <br/>
        <div>
          Synchronization with Multichain takes 1-3 minutes depending on current server workload. Please do not click
          the buttons in danger zone more than 1 time in a row.
        </div>
        <br/>
        <div>Uploaded CSV with profit share data must have the same format as you download from "Download LRT balances".
          <ul>
            <li>The same column order (userId, username, email, amount)</li>
            <li>No header row with column names</li>
            <li>No extra rows with other data</li>
            <li>No currency symbols in amount field</li>
            <li>No comma in amount field. Correct decimal example 1.12</li>
            <li>Only CSV is correct format. No excel or other files.</li>
            <li>No semicolons in raw CSV. If your software reformat CSV file automatically, make sure it didn't change column separators from comma to other symbol. You can check it by right click the file > open with > TextEdit</li>
          </ul>
          <br/>
          Correct raw csv example:
          <br/>
          <br/>
          "2","bt1","info@binarytorrent.com","2.23" <br/>
          "1","root","root@binarytorrent.com","1.00" <br/>

          <br/>
          <div>
            <h4>How to run profit sharing.</h4>
            Click "Upload CSV with profit sharing data". Upload a csv file. "Run profit sharing" button will appear. Click it only one time.
          </div>

        </div>
        <div className="csv-container">
          <div>
            {loadingUpdateLrt ? <span className="loader"/> :
              <button className="button-red" onClick={updateLrt}>Update LRT balances</button>}
          </div>

          <div>
            <input
              accept="csv"
              style={{display: 'none'}}
              id="frontProof"
              type="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            {file === null ? <label htmlFor="frontProof">
                <div className="button-blue upload-button">Upload CSV with profit sharing data</div>
              </label> :
              loadingUploadCsv ? <span className="loader"/> :
                <button className="button-red" onClick={runProfitSharing}>Run profit sharing bonus</button>}
          </div>
        </div>
      </div>
    </div>
  )

}
import React from 'react';
import {Route, Switch} from "react-router";
import {Redirect} from "react-router-dom";
import {DashboardLayout} from "./components/Layouts/DashboardLayout/DashboardLayout";
import {GuestLayout} from "./components/Layouts/GuestLayout/GuestLayout";
import {Login} from "./pages/auth/login/Login";
import {ROUTE} from "./constants/routes";
import {Reports} from "./pages/dashboard/reports/Reports";
import {Users} from "./pages/dashboard/users/Users";
import {UserDetails} from "./pages/dashboard/users/UserDetails";
import {UserTransactions} from "./pages/dashboard/users/components/UserTransactions/UserTransactions";
import {Payouts} from "./pages/dashboard/payouts/Payouts";
import {Epins} from "./pages/dashboard/epins/Epins";
import {News} from "./pages/dashboard/news/News";
import {NewsCreate} from "./pages/dashboard/news/NewsCreate";
import {Translations} from "./pages/dashboard/translations/Translations";
import {WithdrawCryptoApi} from "./pages/dashboard/payouts/components/WithdrawCryptoApi/WithdrawCryptoApi";
import {Resources} from "./pages/dashboard/resources/Resources";
import {Events} from "./pages/dashboard/events/Events";
import {Tree} from "./pages/dashboard/users/components/Tree/Tree";
import {Education} from "./pages/dashboard/education/Education";
import {Exchange} from "./pages/dashboard/exchange/Exchange";
import {Vault} from "./pages/dashboard/vaults/Vault";
import {Orders} from "./pages/dashboard/orders/Orders";
import {Transactions} from "./pages/dashboard/transactions/Transactions";
import {Packs} from "./pages/dashboard/packs/Packs";
import {PackEdit} from "./pages/dashboard/packs/components/PackEdit/PackEdit";
import {Loyalty} from "./pages/dashboard/loyalty/Loyalty";
import {Settings} from "./pages/dashboard/settings/Settings";
import {SettingsEdit} from "./pages/dashboard/settings/components/SettingsEdit/SettingsEdit";
import {LrtPackEdit} from "./pages/dashboard/packs/components/LrtPackEdit/LrtPackEdit";


export const useRoutes = (isAuth) => {
  if (isAuth) {
    return (
      <DashboardLayout>

        <Route path={ROUTE.USERS} exact>
          <Users/>
        </Route>

        <Route path={ROUTE.USER_DETAILS} exact>
          <UserDetails/>
        </Route>

        <Route path={ROUTE.USER_TRANSACTION} exact>
          <UserTransactions/>
        </Route>

        <Route path={ROUTE.USER_TREE} exact>
          <Tree/>
        </Route>

        <Route path={ROUTE.REPORTS} exact>
          <Reports/>
        </Route>

        <Route path={ROUTE.PAYOUTS} exact>
          <Payouts/>
        </Route>

        <Route path={ROUTE.EPINS} exact>
          <Epins/>
        </Route>

        <Route path={ROUTE.NEWS} exact>
          <News/>
        </Route>

        <Route path={ROUTE.NEWS_CREATE} exact>
          <NewsCreate/>
        </Route>

        <Route path={ROUTE.TRANSLATIONS} exact>
          <Translations/>
        </Route>

        <Route path={ROUTE.WITHDRAW_FROM_CRYPTO_API} exact>
          <WithdrawCryptoApi/>
        </Route>

        <Route path={ROUTE.RESOURCES} exact>
          <Resources/>
        </Route>

        <Route path={ROUTE.EDUCATION} exact>
          <Education/>
        </Route>

        <Route path={ROUTE.EVENTS} exact>
          <Events/>
        </Route>

        <Route path={ROUTE.EXCHANGE} exact>
          <Exchange/>
        </Route>

        <Route path={ROUTE.VAULTS} exact>
          <Vault/>
        </Route>

        <Route path={ROUTE.LOYALTY} exact>
          <Loyalty/>
        </Route>

        <Route path={ROUTE.PACKS} exact>
          <Packs/>
        </Route>

        <Route path={ROUTE.PACKS_EDIT} exact>
          <PackEdit/>
        </Route>

        <Route path={ROUTE.LRT_PACKS_EDIT} exact>
          <LrtPackEdit/>
        </Route>

        <Route path={ROUTE.ORDERS} exact>
          <Orders/>
        </Route>

        <Route path={ROUTE.TRANSACTIONS} exact>
          <Transactions/>
        </Route>

        <Route path={ROUTE.SETTINGS} exact>
          <Settings/>
        </Route>
        <Route path={ROUTE.SETTINGS_EDIT} exact>
          <SettingsEdit/>
        </Route>

      </DashboardLayout>
    )
  }
  return (
    <GuestLayout>
      <Switch>
        <Route path={ROUTE.LOGIN}>
          <Login/>
        </Route>
        <Redirect to={ROUTE.LOGIN}/>
      </Switch>
    </GuestLayout>
  )
}
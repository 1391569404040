import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {TRANSACTIONS_TABS} from "./constants/transactionsTabs";
import {TransactionsTable} from "./components/TransactionsTable/TransactionsTable";

export const Transactions = () => {

  const renderContent = () => {
    return <TransactionsTable/>
  }
  return (
    <>
      <Tabs tabs={TRANSACTIONS_TABS} route={ROUTE.TRANSACTIONS}/>

      {renderContent()}
    </>
  )
}
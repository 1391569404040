import React from "react";
import "./UserInfo.css";
import {UserEdit} from "./components/UserEdit/UserEdit";
import {UserStats} from "./components/UserStats/UserStats";
import {LoginHistory} from "./components/LoginHistory/LoginHistory";

export const UserInfo = () => {
  return(
    <div>
        <div className="user-info">
          <UserEdit/>
          <UserStats/>
        </div>
        <div>
          <LoginHistory/>
        </div>
    </div>
  )
}
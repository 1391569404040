import React, {useEffect, useState} from "react";
import {client} from "../../services/client";
import {notification} from "../Notification";


export const BinaryItemList = ({items, isRoot, parent}) => {

  if (items.length === 0) {
    return <ul className="ul-add">
      <li>
        <div className="custom-tree-item custom-tree-item-add network-circle" id={parent.id+"-LEFT"} >+</div>
      </li>
      <li>
        <div className="custom-tree-item custom-tree-item-add network-circle" id={parent.id+"-RIGHT"} >+</div>
      </li>
    </ul>
  } else if (items.length === 1 && !isRoot) {
    return <ul>
      {items[0]?.data?.position === "right" && <li>
        <div className="custom-tree-item custom-tree-item-add network-circle" id={parent.id+"-LEFT"} >+</div>
      </li>}
      {items.map((item) => {
        return <BinaryTreeItem key={item.id} item={item}
                          siblingIds={items.filter(({id}) => id !== item.id).map(({id}) => id)}/>
      })}
      {items[0]?.data?.position === "left" && <li>
        <div className="custom-tree-item custom-tree-item-add network-circle" id={parent.id+"-RIGHT"} >+</div>
      </li>}
    </ul>
  } else if (items.length === 2 || isRoot) {
    return <ul>{items.map((item) => {
      return <BinaryTreeItem key={item.id} item={item} 
                             siblingIds={items.filter(({id}) => id !== item.id).map(({id}) => id)}/>
    })}
    </ul>
  }
}

export const SponsorItemList = ({items}) => {
  return <ul>
    {items.map((item) => {
      return <SponsorTreeItem key={item.id} item={item} 
                             siblingIds={items.filter(({id}) => id !== item.id).map(({id}) => id)}/>
    })}
  </ul>
}

export const CustomTreeItem = ({item, type, siblingIds}) => {
  const [children, setChildren] = useState(item.children);

  const loadChildren = () => {
    try {
      if (type === "binary") {
        client.getBinarySubtree(item.id).then(response => {
          setChildren(response.children);
          dispatchEvent();
        });
      } else if (type === "sponsor") {
        client.getSponsorSubtree(item.id).then(response => {
          setChildren(response.children);
          dispatchEvent();
        });
      }
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const removeChildren = () => {
    setChildren([]);
  }

  const handleToggle = () => {
    if (children.length > 0) {
      removeChildren();
    } else {
      loadChildren();
    }
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.detail.ids.includes(item.id)) {
        setChildren([]);
      }
    }
    window.addEventListener("removeChildren", listener);

    return () => {
      window.removeEventListener("removeChildren", listener)
    }
  }, [])

  const dispatchEvent = () => {
    const event = new CustomEvent("removeChildren", {detail: {ids: siblingIds}});
    window.dispatchEvent(event);
  }

}

export const BinaryTreeItem = ({item, siblingIds}) => {
  const [children, setChildren] = useState(item.children);

  const loadChildren = () => {
    try {
        client.getBinarySubtree(item.id).then(response => {
          setChildren(response.children);
          dispatchEvent();
        });
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const removeChildren = () => {
    setChildren([]);
  }

  const handleToggle = () => {
    if (children.length > 0) {
      removeChildren();
    } else {
      loadChildren();
    }
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.detail.ids.includes(item.id)) {
        setChildren([]);
      }
    }
    window.addEventListener("removeChildren", listener);

    return () => {
      window.removeEventListener("removeChildren", listener)
    }
  }, [])

  const dispatchEvent = () => {
    const event = new CustomEvent("removeChildren", {detail: {ids: siblingIds}});
    window.dispatchEvent(event);
  }
  return (
    <li>
      <div className="network-circle" onClick={handleToggle}>
          <div className="network-profile-icon"/>
      </div>
      <div onClick={handleToggle} className="custom-tree-item">
        <div className="custom-tree-item-inner">
          <div className="custom-tree-table">
            <div className="custom-tree-row">
              <div className="custom-tree-cell custom-tree-cell-caption">{item.data?.username}</div>
              <div className="custom-tree-cell"/>
            </div>
            <div className="custom-tree-row">
              <div className="custom-tree-cell">IMP</div>
              <div className="custom-tree-cell">{item.data?.imp}</div>
            </div>
            <div className="custom-tree-row">
              <div className="custom-tree-cell">Left CV</div>
              <div className="custom-tree-cell">{item.data?.leftCV}</div>
            </div>
            <div className="custom-tree-row">
              <div className="custom-tree-cell">Left team</div>
              <div className="custom-tree-cell">{item.data?.leftTeam}</div>
            </div>
          </div>
          <div className="custom-tree-table">
            <div className="custom-tree-row">
              <div className="custom-tree-cell custom-tree-cell-caption">{item.data?.country}</div>
              <div className="custom-tree-cell"/>
            </div>
            <div className="custom-tree-row">
              <div className="custom-tree-cell">Personal CV</div>
              <div className="custom-tree-cell">{item.data?.personalCV}</div>
            </div>
            <div className="custom-tree-row">
              <div className="custom-tree-cell">Right CV</div>
              <div className="custom-tree-cell">{item.data?.rightCV}</div>
            </div>
            <div className="custom-tree-row">
              <div className="custom-tree-cell">Right team</div>
              <div className="custom-tree-cell">{item.data?.rightTeam}</div>
            </div>
          </div>
        </div>
      </div>

      {(children.length > 0 || !item.hasChildren) && <BinaryItemList items={children} parent={item}/>}
    </li>
  )
}

export const SponsorTreeItem = ({item, siblingIds}) => {
  const [children, setChildren] = useState(item.children);

  const loadChildren = () => {
    try {
      client.getSponsorSubtree(item.id).then(response => {
        setChildren(response.children);
        dispatchEvent();
      });
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const removeChildren = () => {
    setChildren([]);
  }

  const handleToggle = () => {
    if (children.length > 0) {
      removeChildren();
    } else {
      loadChildren();
    }
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.detail.ids.includes(item.id)) {
        setChildren([]);
      }
    }
    window.addEventListener("removeChildren", listener);

    return () => {
      window.removeEventListener("removeChildren", listener)
    }
  }, [])

  const dispatchEvent = () => {
    const event = new CustomEvent("removeChildren", {detail: {ids: siblingIds}});
    window.dispatchEvent(event);
  }
  return (
    <li>
      <div className="network-circle" onClick={handleToggle}>
        <div className="network-profile-icon"/>
      </div>
      <div onClick={handleToggle} className="custom-tree-item custom-tree-item-sponsor">
        <div className="custom-tree-item-inner">
          <div className="custom-tree-item-sponsor-inner">
            <div className="custom-tree-cell-caption">{item.data?.username}</div>
            <div>{item.data?.country}</div>
          </div>
          <div className={item.hasChildren ? "tree-expand" : "tree-collapse"}/>
        </div>
      </div>

      {children.length > 0 && <SponsorItemList items={children}/>}
    </li>
  )
}
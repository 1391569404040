import React from "react";
import {SettingsTable} from "./components/SettingsTable";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {NEWS_TABS} from "./constants/newsTabs";

export const Settings = () => {
  return (
    <>
      <SettingsTable/>
    </>
  )
}
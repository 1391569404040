import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {ResourcesTable} from "./components/ResourceTable/ResourcesTable";
import {RESOURCES_TABS} from "./constants/resourcesTabs";
import {useParams} from "react-router-dom";
import {ResourceCreate} from "./components/ResourceCreate/ResourceCreate";

export const Resources = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "all") {
      return <ResourcesTable/>
    }
    if (tab === "create") {
      return <ResourceCreate/>
    }
  }

  return (
    <>
      <Tabs tabs={RESOURCES_TABS} route={ROUTE.RESOURCES}/>

      {renderContent()}
    </>
  )
}
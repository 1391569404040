import React from "react";
import {Link, useParams} from "react-router-dom";

export const UserLinks = () => {
  const {id} = useParams();

  return(
    <div className="block">
      <div className="title">Links</div>
      <div>
        <Link to={"/dashboard/loyalty/reward?userId=" + id}>Loyalty reward</Link>
      </div>
      <div>
        <Link to={"/dashboard/vaults/all?userId=" + id}>Vaults</Link>
      </div>
      <div>
        <Link to={"/dashboard/orders/all?userId=" + id}>Orders</Link>
      </div>
      <div>
        <Link to={"/dashboard/transactions/all?userId=" + id}>Transactions</Link>
      </div>
      <div>
        <Link to={"/dashboard/epins?userId=" + id}>Epins</Link>
      </div>
      <div>
        <Link to={"/dashboard/payouts?userId=" + id}>Payouts</Link>
      </div>
      <div>
        <Link to={"/dashboard/exchange/orders?userId=" + id}>Exchange</Link>
      </div>
    </div>
  )
}
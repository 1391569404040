import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import "../../Pack.css";

export const LrtPackEdit = () => {
  const {id} = useParams();
  const [loadingUpdateForm, setLoadingUpdateForm] = useState(false);
  const [pack, setPack] = useState();

  useEffect(() => {
    client.getLrtPacks({
      page: 0,
      size: 100,
    })
      .then((r) => {
        setPack(r.content.filter((e) => e.id === +id)[0])
      })
      .catch((error) => notification.warning(error.message))
  }, [id]);

  const updatePack = async (e) => {
    e.preventDefault();
    setLoadingUpdateForm(true);
    await client.updateLrtPack(pack.id, pack)
      .then(() => notification.success("LRT pack updated"))
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingUpdateForm(false));
  }

  return (
    <div className="block pack-edit">
      <div className="title">Edit pack </div>

      <form onSubmit={updatePack}>
        <div>
          <div>Amount</div>
          <input
            type="text"
            name="amount"
            value={pack?.amount}
            onChange={(v) => setPack({...pack, amount: v.target.value})}
          />
        </div>
        <div>
          <div>Price</div>
          <input
            type="text"
            name="price"
            value={pack?.price}
            onChange={(v) => setPack({...pack, price: v.target.value})}
          />
        </div>
        <div className="user-edit-form-button-container">
          {loadingUpdateForm ? <span className="loader"/> :
            <button className="button-green" type="submit">Save</button>}
        </div>
      </form>
    </div>
  )
}
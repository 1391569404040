import React from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {ROUTE} from "../../../constants/routes";
import {useParams} from "react-router-dom";
import {PACKS_TABS} from "./constants/packsTabs";
import {MembershipPacksTable} from "./components/MembershipPacksTable/MembershipPacksTable";
import {PackEdit} from "./components/PackEdit/PackEdit";
import {LrtPacksTable} from "./components/LrtPacksTable/LrtPacksTable";

export const Packs = () => {
  const {tab} = useParams();

  const renderContent = () => {
    if (tab === "membership") {
      return <MembershipPacksTable/>
    }
    if (tab === "edit") {
      return <PackEdit/>
    }
    if (tab === "lrt") {
      return <LrtPacksTable/>
    }
  }
  return (
    <>
      <Tabs tabs={PACKS_TABS} route={ROUTE.PACKS}/>

      {renderContent()}
    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {client} from "../../../../../services/client";
import {searchParamsToObject, toEur} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {ROUTE} from "../../../../../constants/routes";

export const LrtPacksTable = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [total, setTotal] = useState(1);
  const [updated, setUpdated] = useState(0);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'price', value: 'Price', direction: 'asc', sorted: true},
    {name: 'actions', value: '', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getLrtPacks({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage, history]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, updated, history]);


  const renderActions = (pack) => {
    return (
      <>
        <Tooltip title="Edit" aria-label="edit">
          <Edit className="action-icon"
                onClick={() => history.push(ROUTE.LRT_PACKS_EDIT.replace(":id", pack.id).replace(":tab", "edit"))}/>
        </Tooltip>
      </>
    )
  }


  return (
    <div className="block">
      <div className="title">Packs</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{toEur(e.amount, false)}</TableCell>
                <TableCell>{toEur(e.price)}</TableCell>
                <TableCell>{renderActions(e)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
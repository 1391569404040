import React, {useState} from "react";
import {client} from "../../../../../../../services/client";
import {notification} from "../../../../../../../components/Notification";
import {toEur} from "../../../../../../../utils/tools";

export const WalletForm = ({user}) => {
  const [loadingTopupCash, setLoadingTopupCash] = useState(false);
  const [loadingWithdrawCash, setLoadingWithdrawCash] = useState(false);
  const [loadingTopupTrading, setLoadingTopupTrading] = useState(false);
  const [loadingWithdrawTrading, setLoadingWithdrawTrading] = useState(false);
  const [loadingTopupMandatory, setLoadingTopupMandatory] = useState(false);
  const [loadingWithdrawMandatory, setLoadingWithdrawMandatory] = useState(false);
  const [loadingTopupDebt, setLoadingTopupDebt] = useState(false);
  const [loadingWithdrawDebt, setLoadingWithdrawDebt] = useState(false);
  const [loadingTopupLmgt, setLoadingTopupLmgt] = useState(false);
  const [loadingWithdrawLmgt, setLoadingWithdrawLmgt] = useState(false);
  const [loadingTopupLrt, setLoadingTopupLrt] = useState(false);
  const [loadingWithdrawLrt, setLoadingWithdrawLrt] = useState(false);
  const [loadingBinaryBonus, setLoadingBinaryBonus] = useState(false);

  const [cashValue, setCashValue] = useState("");
  const [cashNote, setCashNote] = useState("");
  const [tradingValue, setTradingValue] = useState("");
  const [tradingNote, setTradingNote] = useState("");
  const [mandatoryValue, setMandatoryValue] = useState("");
  const [mandatoryNote, setMandatoryNote] = useState("");
  const [debtValue, setDebtValue] = useState("");
  const [debtNote, setDebtNote] = useState("");
  const [lmgtValue, setLmgtValue] = useState("");
  const [lmgtNote, setLmgtNote] = useState("");
  const [lrtValue, setLrtValue] = useState("");
  const [lrtNote, setLrtNote] = useState("");
  const [binaryBonusValue, setBinaryBonusValue] = useState("");

  const balanceType = ["CASH", "TRADING", "LMGT", "DEBT", "MANDATORY", "LRT"];

  const resetLoading = () => {
    setLoadingTopupCash(false);
    setLoadingTopupTrading(false);
    setLoadingTopupMandatory(false);
    setLoadingTopupDebt(false);
    setLoadingTopupLmgt(false);
    setLoadingTopupLrt(false);
    setLoadingWithdrawCash(false);
    setLoadingWithdrawTrading(false);
    setLoadingWithdrawMandatory(false);
    setLoadingWithdrawDebt(false);
    setLoadingWithdrawLmgt(false);
    setLoadingWithdrawLrt(false);
  }

  const resetFields = () => {
    setCashValue("");
    setTradingValue("");
    setMandatoryValue("");
    setLmgtValue("");
    setLrtValue("");
    setCashNote("");
    setTradingNote("");
    setMandatoryNote("");
    setLmgtNote("");
    setLrtNote("");
  }

  const topup = async (e, type) => {
    e.preventDefault();
    let value;
    let note;
    if (type === balanceType[0]) {
      value = +cashValue;
      note = cashNote;
      setLoadingTopupCash(true);
    } else if (type === balanceType[1]) {
      value = +tradingValue;
      note = tradingNote;
      setLoadingTopupTrading(true);
    } else if (type === balanceType[2]) {
      value = +lmgtValue;
      note = lmgtNote;
      setLoadingTopupLmgt(true);
    } else if (type === balanceType[3]) {
      value = +debtValue;
      note = debtNote;
      setLoadingTopupDebt(true);
    } else if (type === balanceType[4]) {
      value = +mandatoryValue;
      note = mandatoryNote;
      setLoadingTopupMandatory(true);
    } else if (type === balanceType[5]) {
      value = +lrtValue;
      note = lrtNote;
      setLoadingTopupLrt(true);
    } else {
      resetLoading();
      return;
    }
    if (value <= 0) {
      resetLoading();
      return;
    }
    await client.topupWallet({
      userId: user.id,
      amount: value,
      balanceType: type,
      reason: note,
    })
      .then(() => {
        notification.success("Success deposit of " + toEur(value, false) + " " + type.toLowerCase());
        resetFields();
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => resetLoading());
  }

  const withdraw = async (e, type) => {
    e.preventDefault();
    let value;
    let note;
    if (type === balanceType[0]) {
      value = +cashValue;
      note = cashNote;
      setLoadingWithdrawCash(true);
    } else if (type === balanceType[1]) {
      value = +tradingValue;
      note = tradingNote;
      setLoadingWithdrawTrading(true);
    } else if (type === balanceType[2]) {
      value = +lmgtValue;
      note = lmgtNote;
      setLoadingWithdrawLmgt(true);
    } else if (type === balanceType[3]) {
      value = +debtValue;
      note = debtNote;
      setLoadingWithdrawDebt(true);
    } else if (type === balanceType[4]) {
      value = +mandatoryValue;
      note = mandatoryNote;
      setLoadingWithdrawMandatory(true);
    } else if (type === balanceType[5]) {
      value = +lrtValue;
      note = lrtNote;
      setLoadingWithdrawLrt(true);
    } else {
      resetLoading();
      return;
    }
    if (value <= 0) {
      resetLoading();
      return;
    }
    await client.withdrawWallet({
      userId: user.id,
      amount: value,
      balanceType: type,
      reason: note,
    })
      .then(() => {
        notification.success("Success withdraw of " + toEur(value, false) + " " + type.toLowerCase());
        resetFields();
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => resetLoading());
  }

  const manualBinaryBonus = async (e) => {
    e.preventDefault();
    const value = +binaryBonusValue;
    if (value <= 0) {
      return;
    }
    setLoadingBinaryBonus(true);
    await client.manualBinaryBonus({
      userId: user.id,
      amount: value
    })
      .then(() => {
        setBinaryBonusValue("");
        notification.success("Binary bonus added");
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoadingBinaryBonus(false));
  }

  return (
    <div className="user-edit-topup-container">
      <form className="user-edit-topup-row">
        <div>
          <div>Cash wallet</div>
          <input
            type="text"
            value={cashValue}
            onChange={(e) => setCashValue(e.target.value)}
          />
        </div>
        <div>
          <div>Note</div>
          <input
            type="text"
            value={cashNote}
            onChange={(e) => setCashNote(e.target.value)}
          />
        </div>
        {loadingTopupCash ? <span className="loader"/> :
          <button className="button-blue" onClick={(e) => topup(e, balanceType[0])}>Deposit</button>}
        {loadingWithdrawCash ? <span className="loader"/> :
          <button className="button-red" onClick={(e) => withdraw(e, balanceType[0])}>Withdraw</button>}
      </form>

      <form className="user-edit-topup-row">
        <div>
          <div>Trading wallet</div>
          <input
            type="text"
            value={tradingValue}
            onChange={(e) => setTradingValue(e.target.value)}
          />
        </div>
        <div>
          <div>Note</div>
          <input
            type="text"
            value={tradingNote}
            onChange={(e) => setTradingNote(e.target.value)}
          />
        </div>
        {loadingTopupTrading ? <span className="loader"/> :
          <button className="button-blue" onClick={(e) => topup(e, balanceType[1])}>Deposit</button>}
        {loadingWithdrawTrading ? <span className="loader"/> :
          <button className="button-red" onClick={(e) => withdraw(e, balanceType[1])}>Withdraw</button>}
      </form>

      <form className="user-edit-topup-row">
        <div>
          <div>Mandatory wallet</div>
          <input
            type="text"
            value={mandatoryValue}
            onChange={(e) => setMandatoryValue(e.target.value)}
          />
        </div>
        <div>
          <div>Note</div>
          <input
            type="text"
            value={mandatoryNote}
            onChange={(e) => setMandatoryNote(e.target.value)}
          />
        </div>
        {loadingTopupMandatory ? <span className="loader"/> :
          <button className="button-blue" onClick={(e) => topup(e, balanceType[4])}>Deposit</button>}
        {loadingWithdrawMandatory ? <span className="loader"/> :
          <button className="button-red" onClick={(e) => withdraw(e, balanceType[4])}>Withdraw</button>}
      </form>

      <form className="user-edit-topup-row">
        <div>
          <div>LMGT</div>
          <input
            type="text"
            value={lmgtValue}
            onChange={(e) => setLmgtValue(e.target.value)}
          />
        </div>
        <div>
          <div>Note</div>
          <input
            type="text"
            value={lmgtNote}
            onChange={(e) => setLmgtNote(e.target.value)}
          />
        </div>
        {loadingTopupLmgt ? <span className="loader"/> :
          <button className="button-blue" onClick={(e) => topup(e, balanceType[2])}>Deposit</button>}
        {loadingWithdrawLmgt ? <span className="loader"/> :
          <button className="button-red" onClick={(e) => withdraw(e, balanceType[2])}>Withdraw</button>}
      </form>

      <form className="user-edit-topup-row">
        <div>
          <div>LRT</div>
          <input
            type="text"
            value={lrtValue}
            onChange={(e) => setLrtValue(e.target.value)}
          />
        </div>
        <div>
          <div>Note</div>
          <input
            type="text"
            value={lrtNote}
            onChange={(e) => setLrtNote(e.target.value)}
          />
        </div>
        {loadingTopupLrt ? <span className="loader"/> :
          <button className="button-blue" onClick={(e) => topup(e, balanceType[5])}>Deposit</button>}
        {loadingWithdrawLrt ? <span className="loader"/> :
          <button className="button-red" onClick={(e) => withdraw(e, balanceType[5])}>Withdraw</button>}
      </form>

      <form className="user-edit-topup-row">
        <div>
          <div>Debt</div>
          <input
            type="text"
            value={debtValue}
            onChange={(e) => setDebtValue(e.target.value)}
          />
        </div>
        <div>
          <div>Note</div>
          <input
            type="text"
            value={debtNote}
            onChange={(e) => setDebtNote(e.target.value)}
          />
        </div>
        {loadingTopupDebt ? <span className="loader"/> :
          <button className="button-blue" onClick={(e) => topup(e, balanceType[3])}>Add debt</button>}
        {loadingWithdrawTrading ? <span className="loader"/> :
          <button className="button-red" onClick={(e) => withdraw(e, balanceType[3])}>Subtract debt</button>}
      </form>

      <div className="user-edit-topup-row">
        <div>
          <div>Binary bonus</div>
          <input
            type="text"
            value={binaryBonusValue}
            onChange={(e) => setBinaryBonusValue(e.target.value)}
          />
        </div>
        <div>
          <div>Note</div>
          <input
            type="text"
            name="noteBinaryBonus"
            disabled
          />
        </div>
        {loadingBinaryBonus ? <span className="loader"/> :
          <button className="button-blue" onClick={manualBinaryBonus}>Add</button>}
      </div>

    </div>
  )
}